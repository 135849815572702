<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Check same sender / same consignee for export
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.costumer"
                hide-details
                dense
                item-text="shortName"
                :items="dataStore.generalCustomers"
                item-value="id"
                label="General customer"
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.sender"
                hide-details
                dense
                label="Sender name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="form.destinationCountry"
                hide-details
                dense
                label="Destination country*"
                :items="dataStore.originCountries"
                item-key="code"
                item-text="code"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="6">
              <DateTimePicker
                v-model="form.from"
                label="From*"
                :error-messages="errorFromDate"
              ></DateTimePicker>
            </v-col>
            <v-col cols="6">
              <DateTimePicker
                v-model="form.till"
                label="Till*"
                :error-messages="errorTillDate"
              ></DateTimePicker>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isFormValid || loading"
          text
          @click="downloadExcelFile()"
        >
          Download Excel file</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { FrequentActionsApi, SameSenderCheckFromModel } from "@/openapi";
import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
import moment from "moment";
import { useDataStore } from "@/stores/data-store";
import { emitError } from "@/event-bus";
interface IProps {
  show: boolean;
}

const dataStore = useDataStore();
const api = new FrequentActionsApi(undefined, "");
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);

const loading = ref(false);
const defaultForm = {
  costumer: null,
  sender: null,
  destinationCountry: null,
  from: null,
  till: null,
};
const form = ref<SameSenderCheckFromModel>(structuredClone(defaultForm));

const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    loading.value = false;
    emit("close");
  },
});

const isFormValid = computed(() => {
  return (
    !!form.value.destinationCountry &&
    isFromDateValid.value &&
    isTillDateValid.value
  );
});

const isFromDateValid = computed(() => {
  return !!form.value.from && moment(form.value.from).isBefore(Date.now());
});
const errorFromDate = computed(() => {
  const errors: string[] = [];
  if (!!form.value.from && !isFromDateValid.value) {
    errors.push("From date must be before today and Till date");
  }
  return errors;
});

const isTillDateBeforeToday = computed(() => {
  return !!form.value.till && moment(form.value.till).isBefore(Date.now());
});
const isTillDateAfterFromDate = computed(() => {
  return (
    !!form.value.till &&
    !!form.value.from &&
    moment(form.value.till).isAfter(form.value.from)
  );
});
const isTillDateValid = computed(() => {
  return (
    !!form.value.till &&
    isTillDateBeforeToday.value &&
    isTillDateAfterFromDate.value
  );
});
const errorTillDate = computed(() => {
  const errors: string[] = [];
  if (form.value.till) {
    if (!isTillDateBeforeToday.value) {
      errors.push("Till date must be before today");
    }
    if (!isTillDateAfterFromDate.value) {
      errors.push("Till date must be after From date");
    }
  }
  return errors;
});

const downloadExcelFile = () => {
  loading.value = true;
  api
    .sameSenderCheck(form.value, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = getFileNameFromContentDispositionHeader(response);
      downloadFile(response.data, fileName!);
      closeDialog();
    })
    .catch(() => {
      emitError("Something went wrong while recreating invoices.");
      loading.value = false;
    });
};

const closeDialog = () => {
  loading.value = false;
  form.value = structuredClone(defaultForm);
  emit("close");
};

onBeforeMount(() => {
  dataStore.fetchGeneralCustomers();
  dataStore.fetchOriginCountries();
});
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
