<template>
  <v-card class="pa-3 references-card mb-1">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Invoice address</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="detail.invoiceAddress.customerWithCredit"
          hide-details
          label="Cus. with credit"
        ></v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.invoiceAddress.name"
          label="Name"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.invoiceAddress.address"
          label="Address"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.invoiceAddress.additionalAddress"
          label="Add. addr."
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="detail.invoiceAddress.zipcode"
          label="Zipcode"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.invoiceAddress.city"
          label="City"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="detail.invoiceAddress.country"
          :items="dataStore.originCountries"
          :item-text="({ description, code }) => `${code} - ${description}`"
          :item-value="({ code }) => code"
          dense
          label="Country"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.invoiceAddress.eoriNumber"
          dense
          label="EORI number"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field dense hide-details outlined></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="detail.invoiceAddress.remark"
          label="Invoicing remarks"
          outlined
          hide-details
          :counter="200"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}
defineProps<IProps>();
const dataStore = useDataStore();
</script>
