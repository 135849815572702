<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Get taxforms for parcel ids
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-textarea
                v-model="ids"
                name="Enter parcel ids"
                hide-details
                dense
                label="Parcel ids"
                outlined
                :disabled="loading"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container> </v-card-text
      ><v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="!hasIds || loading" @click="downloadZip">
          Download ZIP file
        </v-btn>
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { FrequentActionsApi } from "@/openapi";
import { emitError } from "@/event-bus";
import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
interface IProps {
  show: boolean;
}
const api = new FrequentActionsApi(undefined, "");
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);

const loading = ref(false);
const ids = ref("");
const idsist = computed(() =>
  ids.value.split("\n").filter((item) => item.trim() !== ""),
);
const hasIds = computed(() => idsist.value.length > 0);
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const closeDialog = () => {
  loading.value = false;
  ids.value = "";
  emit("close");
};

const downloadZip = () => {
  loading.value = true;
  api
    .getTaxFormsForParcels(idsist.value, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = getFileNameFromContentDispositionHeader(response);
      downloadFile(response.data, fileName!);
      closeDialog();
    })
    .catch(() => {
      emitError("Something went wrong while getting taxforms.");
      loading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
