<template>
  <v-card class="pa-3 order-details-card mb-1">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Order details</v-card-title>
    </v-toolbar>
    <v-row>
      <div class="checkbox-container">
        <v-col cols="12">
          <v-row>
            <v-col cols="6" class="col-check-box">
              <v-checkbox
                v-model="detail.orderDetails.eza"
                hide-details
                label="EZA"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" class="col-check-box">
              <v-checkbox
                v-model="detail.orderDetails.chargesToSender"
                hide-details
                label="Charges to sender"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" class="col-check-box">
              <v-checkbox
                v-model="detail.orderDetails.transitT1"
                hide-details
                label="Transit T1"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" class="col-check-box">
              <v-checkbox
                v-model="detail.orderDetails.clearanceByCustomer"
                hide-details
                label="Clear. by customs"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="6" class="col-check-box">
              <v-checkbox
                v-model="detail.orderDetails.returnedGoods"
                hide-details
                label="Returned goods"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" class="col-check-box">
              <v-checkbox
                v-model="detail.orderDetails.sonderauslagerung"
                hide-details
                label="Sonderauslagerung"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <v-col cols="8">
        <v-select
          v-model="detail.orderDetails.customerId"
          :items="dataStore.generalCustomers"
          item-text="shortName"
          item-value="id"
          hide-details
          dense
          label="customer"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-btn color="primary">Pre-alert</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";
import ShipmentInfoParcels from "./dialogs/ShipmentInfoParcelsDialog.vue";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();
const dataStore = useDataStore();
</script>

<style lang="scss">
.col-check-box {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
