<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2"> New milestone </v-card-title>
    <v-card-text>
      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="remark"
            label="remarks"
            outlined
            hide-details
            auto-grow
            row-height="70"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save()"> Save </v-btn>
      <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { ShipmentDetailMilestone } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  openDialog: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["update:open-dialog", "save"]);

const dataStore = useDataStore();
const remark = ref<string | null>();

const closeDialog = () => {
  emit("update:open-dialog", false);
};

const save = () => {
  emit("save", remark.value);
  remark.value = null;
};
</script>
