<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-btn
          class="mt-5 ml-5"
          color="primary"
          :disabled="isNewEcomCustomer"
          :loading="isSettingUpCreate"
          @click="createNewEcomCustomer"
        >
          Create
        </v-btn>
        <ValidationObserver v-slot="{ invalid }" ref="observer">
          <form>
            <v-data-table
              :headers="headers"
              :items="customers"
              :single-expand="true"
              :server-items-length="totalAmountOfItems"
              :options.sync="options"
              :footer-props="footerOptions"
              :expanded.sync="expanded"
              item-key="id"
              height="calc(100vh - 187px)"
              fixed-header
              fixed-footer
              show-expand
              class="elevation-1"
              :search="search"
              :loading="isLoading"
              @update:options="onUpdateOptions"
              @item-expanded="openCustomer"
            >
              <template #top>
                <v-toolbar flat>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    @keydown.enter.prevent="getCustomers"
                  ></v-text-field>
                </v-toolbar>
              </template>

              <template #item.data-table-expand="{ item, expand, isExpanded }">
                <td class="text-start">
                  <v-btn
                    icon
                    class="v-data-table__expand-icon"
                    :class="{
                      'v-data-table__expand-icon--active': isExpanded,
                    }"
                    :disabled="!item.id"
                    @click="expand(!isExpanded)"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </td>
              </template>
              <template #expanded-item="{ headers, item }">
                <td v-if="selectedCustomer" :colspan="headers.length">
                  <v-flex class="pa-3 pb-5">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            color="error"
                            dark
                            class="mb-2"
                            @click="showConfirmDelete"
                          >
                            Delete customer
                          </v-btn>
                          <v-btn
                            color="success"
                            dark
                            class="mb-2 ml-5"
                            :disabled="invalid"
                            @click="saveCustomer"
                          >
                            Save customer
                          </v-btn>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-card class="mb-3">
                            <v-toolbar flat color="primary" class="mb-5" dark>
                              <v-toolbar-title>General</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Old customer"
                                    rules="required"
                                  >
                                    <v-autocomplete
                                      v-model="selectedCustomer.oldCustomerId"
                                      :items="legacyCustomers"
                                      item-value="id"
                                      item-text="shortName"
                                      name="Legacy customer"
                                      label="Select a legacy customer"
                                      dense
                                      outlined
                                      :error-messages="errors"
                                    ></v-autocomplete>
                                  </ValidationProvider>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Match code"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.matchCode"
                                      class="mb-3"
                                      dense
                                      label="Matchcode*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    :value="selectedCustomer.debtorNumber"
                                    hide-details
                                    disabled
                                    dense
                                    label="Debtor number"
                                    outlined
                                  ></v-text-field
                                ></v-col>
                              </v-row>
                            </v-card-text>

                            <v-divider></v-divider>
                          </v-card>
                          <v-card class="mb-3">
                            <v-card-title>Operational information</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="
                                      selectedCustomer.amountOfDaysForAutomaticReturn
                                    "
                                    hide-details
                                    dense
                                    label="Amount of days for automatic return for import shipments"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="
                                      selectedCustomer.amountOfDaysForAutomaticReturnExport
                                    "
                                    hide-details
                                    dense
                                    label="Amount of days for automatic return for export shipments"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="
                                      selectedCustomer.canBeGeneralCustomer
                                    "
                                    hide-details
                                    class="mt-0"
                                    label="Allowed to be a general customer"
                                  ></v-checkbox
                                ></v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-card color="lighten-3" class="mb-5">
                            <v-card-title>Customer address</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Customer name"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.customerName"
                                      dense
                                      label="Name*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="4">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Customer street"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.customerStreet"
                                      dense
                                      label="Street*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="8">
                                  <v-text-field
                                    v-model="selectedCustomer.customerStreetAdd"
                                    hide-details
                                    dense
                                    label="Additional address"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Customer zip"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.customerZip"
                                      dense
                                      label="Zipcode*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="8">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Customer city"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.customerCity"
                                      dense
                                      label="City*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Customer country"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="selectedCustomer.customerCountry"
                                      :items="originCountries"
                                      item-text="description"
                                      item-value="code*"
                                      dense
                                      clearable
                                      label="Country*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-select>
                                  </ValidationProvider>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>

                          <v-card color="lighten-3" class="mb-5">
                            <v-card-title>Contact details</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Billing email address Import, Part 1"
                                    rules="email"
                                  >
                                    <v-text-field
                                      v-model="
                                        selectedCustomer.billingEmailImportPart1
                                      "
                                      dense
                                      label="Billing email address Import, Part 1"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Billing email address Import, Part 2"
                                    rules="email"
                                  >
                                    <v-text-field
                                      v-model="
                                        selectedCustomer.billingEmailImportPart2
                                      "
                                      dense
                                      label="Billing email address Import, Part 2"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Billing email address Export, Part 1"
                                    rules="email"
                                  >
                                    <v-text-field
                                      v-model="
                                        selectedCustomer.billingEmailExportPart1
                                      "
                                      dense
                                      label="Billing email address Export, Part 1"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Billing email address Export, Part 2"
                                    rules="email"
                                  >
                                    <v-text-field
                                      v-model="
                                        selectedCustomer.billingEmailExportPart2
                                      "
                                      dense
                                      label="Billing email address Export, Part 2"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Billing email address Single positions, Part 1"
                                    rules="email"
                                  >
                                    <v-text-field
                                      v-model="
                                        selectedCustomer.billingEmailSinglePosPart1
                                      "
                                      dense
                                      label="Billing email address Single positions, Part 1"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Billing email address Single positions, Part 2"
                                    rules="email"
                                  >
                                    <v-text-field
                                      v-model="
                                        selectedCustomer.billingEmailSinglePosPart2
                                      "
                                      dense
                                      label="Billing email address Single positions, Part 2"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Reminder email address"
                                    rules="email"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.reminderEmail"
                                      dense
                                      label="Reminder email address"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                          <v-card color="lighten-3" class="mb-5">
                            <v-card-title>Invoice address</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Invoice name"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.invoiceName"
                                      dense
                                      label="Name*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="4">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Invoice street"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.invoiceStreet"
                                      dense
                                      label="Street*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="8">
                                  <v-text-field
                                    v-model="selectedCustomer.invoiceStreetAdd"
                                    hide-details
                                    dense
                                    label="Additional address"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Invoice zip"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.invoiceZip"
                                      dense
                                      label="Zipcode*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="8">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Invoice city"
                                    rules="required"
                                  >
                                    <v-text-field
                                      v-model="selectedCustomer.invoiceCity"
                                      dense
                                      label="City*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col cols="12">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="Invoice country"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="selectedCustomer.invoiceCountry"
                                      dense
                                      :items="originCountries"
                                      item-text="description"
                                      item-value="code"
                                      label="Country*"
                                      outlined
                                      :error-messages="errors"
                                    ></v-select>
                                  </ValidationProvider>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>

                                                    <v-card
                                                        color="lighten-3"
                                                        class="mb-5"
                                                    >
                                                        <v-card-title
                                                            >Accounting
                                                            information</v-card-title
                                                        >
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col cols="6">
                                                                    <ValidationProvider
                                                                        name="VAT-ID"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="
                                                                                selectedCustomer.vatId
                                                                            "
                                                                            dense
                                                                            label="VAT-ID"
                                                                            outlined
                                                                        ></v-text-field>
                                                                    </ValidationProvider>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <ValidationProvider
                                                                        name="Credit limit"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="
                                                                                selectedCustomer.creditLimit
                                                                            "
                                                                            dense
                                                                            label="Credit limit"
                                                                            outlined
                                                                        ></v-text-field>
                                                                    </ValidationProvider>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-checkbox
                                                                        v-model="
                                                                            selectedCustomer.isCustomerWithCredit
                                                                        "
                                                                        hide-details
                                                                        class="mt-0"
                                                                        label="Customer with credit"
                                                                    ></v-checkbox>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-checkbox
                                                                        v-model="
                                                                            selectedCustomer.noTax
                                                                        "
                                                                        class="mb-3 mt-0"
                                                                        hide-details
                                                                        label="No tax"
                                                                    ></v-checkbox>
                                                                    <hr />
                                                                    <v-radio-group
                                                                        v-model.number="
                                                                            selectedCustomer.noTaxTypeValue
                                                                        "
                                                                        row
                                                                        :disabled="
                                                                            !selectedCustomer.noTax
                                                                        "
                                                                    >
                                                                        <v-radio
                                                                            label="EU"
                                                                            :value="
                                                                                0
                                                                            "
                                                                        ></v-radio>
                                                                        <v-radio
                                                                            label="Drittland"
                                                                            :value="
                                                                                1
                                                                            "
                                                                        ></v-radio>
                                                                        <v-radio
                                                                            label="Organschaft"
                                                                            :value="
                                                                                2
                                                                            "
                                                                        ></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-select
                                                                        v-model="
                                                                            selectedCustomer.invoiceReminderLanguageId
                                                                        "
                                                                        hide-details
                                                                        dense
                                                                        label="invoice / reminder language"
                                                                        outlined
                                                                        item-text="value"
                                                                        item-value="key"
                                                                        :items="
                                                                            languages
                                                                        "
                                                                    ></v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <ValidationProvider
                                                                        name="Invoice footer text"
                                                                    >
                                                                        <v-select
                                                                            v-model="
                                                                                selectedCustomer.invoiceFooterTextId
                                                                            "
                                                                            :items="
                                                                                invoiceFooterTexts
                                                                            "
                                                                            item-value="key"
                                                                            item-text="value"
                                                                            clearable
                                                                            dense
                                                                            outlined
                                                                            label="Invoice footer text"
                                                                        ></v-select>
                                                                    </ValidationProvider>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-textarea
                                                                        v-model="
                                                                            selectedCustomer.invoiceRemarks
                                                                        "
                                                                        dense
                                                                        hide-details
                                                                        outlined
                                                                        name="Invoice remarks"
                                                                        label="Invoice remarks"
                                                                        rows="3"
                                                                    ></v-textarea>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12" md="5">
                                                    <v-tabs
                                                        v-model="
                                                            selectedBillingType
                                                        "
                                                        background-color="transparent"
                                                        color="primary"
                                                        grow
                                                    >
                                                        <v-tab
                                                            v-for="(
                                                                item, index
                                                            ) in billingTypes"
                                                            :key="index"
                                                        >
                                                            {{ item }}
                                                        </v-tab>
                                                    </v-tabs>

                          <v-tabs-items v-model="selectedBillingType">
                            <v-tab-item>
                              <v-card color="lighten-3" class="mb-5">
                                <v-card-title
                                  >Billing timing (Import)</v-card-title
                                >
                                <v-card-text>
                                  <ValidationProvider name="Billing days">
                                    <v-select
                                      v-model="
                                        selectedCustomer.importBillingDays
                                      "
                                      :items="days"
                                      chips
                                      clearable
                                      label="billing days"
                                      multiple
                                      outlined
                                      hint="Select billing days"
                                    ></v-select>
                                  </ValidationProvider>
                                  <ul class="billing-list">
                                    <li>
                                      <ValidationProvider name="Billing time">
                                        <v-text-field
                                          v-model="selectedCustomer.importTime"
                                          class="mr-2 billing-list__time"
                                          label="Time"
                                          type="time"
                                        ></v-text-field>
                                      </ValidationProvider>
                                    </li>
                                  </ul>
                                  <v-select
                                    v-model="
                                      selectedCustomer.importPaymentTermId
                                    "
                                    :items="paymentTerms"
                                    item-value="key"
                                    item-text="value"
                                    dense
                                    clearable
                                    label="Payment term"
                                    outlined
                                    hint="Select payment term"
                                  ></v-select>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <v-tab-item>
                              <v-card color="lighten-3" class="mb-5">
                                <v-card-title
                                  >Billing timing (Export)</v-card-title
                                >
                                <v-card-text>
                                  <v-select
                                    v-model="selectedCustomer.exportBillingDays"
                                    :items="days"
                                    chips
                                    label="billing days"
                                    multiple
                                    outlined
                                    hint="Select billing days"
                                  ></v-select>
                                  <ul class="billing-list">
                                    <li>
                                      <ValidationProvider name="Billing time">
                                        <v-text-field
                                          v-model="selectedCustomer.exportTime"
                                          class="mr-2 billing-list__time"
                                          label="Time"
                                          type="time"
                                        ></v-text-field>
                                      </ValidationProvider>
                                    </li>
                                  </ul>
                                  <v-select
                                    v-model="
                                      selectedCustomer.exportPaymentTermId
                                    "
                                    :items="paymentTerms"
                                    item-value="key"
                                    item-text="value"
                                    dense
                                    clearable
                                    label="Payment term"
                                    outlined
                                    hint="Select payment term"
                                  ></v-select>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <v-tab-item>
                              <v-card color="lighten-3" class="mb-5">
                                <v-card-title
                                  >Billing timing (Single
                                  positions)</v-card-title
                                >
                                <v-card-text>
                                  <v-select
                                    v-model="
                                      selectedCustomer.singlePosBillingDays
                                    "
                                    :items="days"
                                    label="billing days"
                                    multiple
                                    outlined
                                    hint="Select billing days"
                                  ></v-select>
                                  <ul class="billing-list">
                                    <li>
                                      <ValidationProvider name="Billing time">
                                        <v-text-field
                                          v-model="
                                            selectedCustomer.singlePosTime
                                          "
                                          class="mr-2 billing-list__time"
                                          label="Time"
                                          type="time"
                                        ></v-text-field>
                                      </ValidationProvider>
                                    </li>
                                  </ul>
                                  <v-select
                                    v-model="
                                      selectedCustomer.singlePosPaymentTermId
                                    "
                                    :items="paymentTerms"
                                    item-value="key"
                                    item-text="value"
                                    dense
                                    clearable
                                    label="Payment term"
                                    outlined
                                    hint="Select payment term"
                                  ></v-select>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>
                          <v-card color="lighten-3" class="mb-5">
                            <v-card-title>Power of Attorney</v-card-title>
                            <v-card-text>
                              <v-treeview
                                class="power-of-attorney"
                                selection-type="independent"
                                :items="powerOfAttorneyItems"
                              >
                                <template #label="{ item }">
                                  <div class="poa">
                                    <v-checkbox
                                      v-model="
                                        selectedCustomer[item.enabledPropName]
                                      "
                                      :ripple="false"
                                      :disabled="!!getFileData(item)?.id"
                                    >
                                    </v-checkbox>

                                    <v-file-input
                                      v-if="!getFileData(item)?.id"
                                      v-model="powerOfAttorney[item.value]"
                                      class="mt-5"
                                      dense
                                      color="primary accent-4"
                                      counter
                                      :label="item.name"
                                      placeholder="Select your file"
                                      prepend-icon="mdi-paperclip"
                                      outlined
                                      :show-size="1000"
                                      :disabled="
                                        !selectedCustomer[item.enabledPropName]
                                      "
                                    >
                                    </v-file-input>
                                    <div v-else class="existing-file">
                                      <v-text-field
                                        :value="getFileData(item).name"
                                        :label="item.name"
                                        dense
                                        outlined
                                        disabled
                                        hide-details
                                        prepend-icon="mdi-paperclip"
                                      ></v-text-field>
                                      <v-icon
                                        class="existing-file__remove"
                                        color="error"
                                        @click="
                                          removeExistingFile(
                                            item.value,
                                            item.propName,
                                          )
                                        "
                                        >mdi-close</v-icon
                                      >
                                    </div>
                                  </div>
                                </template>
                              </v-treeview>
                            </v-card-text>
                          </v-card>
                          <v-card color="lighten-3" class="mb-5">
                            <v-card-title
                              >Reference to templates / schemes</v-card-title
                            >
                            <v-card-text>
                              <v-select
                                v-model="selectedCustomer.schemeId"
                                hide-details
                                class="mb-3"
                                dense
                                item-text="value"
                                item-value="key"
                                :items="schemes"
                                label="connect to a scheme"
                                outlined
                              ></v-select>
                              <v-select
                                v-model="selectedCustomer.ratecardId"
                                class="mb-3"
                                hide-details
                                dense
                                item-text="value"
                                item-value="key"
                                :items="rateCards"
                                label="connect to a ratecard"
                                outlined
                              ></v-select>
                              <v-select
                                v-model="selectedCustomer.csvInvoiceExportId"
                                class="mb-3"
                                hide-details
                                dense
                                item-text="value"
                                item-value="key"
                                :items="invoices"
                                label="connect to csv invoice export"
                                outlined
                              ></v-select>
                              <p class="v-card__title">
                                Connect to an Email template
                              </p>
                              <v-tabs
                                v-model="selectedEmailLanguage"
                                background-color="transparent"
                                color="primary"
                                grow
                              >
                                <v-tab
                                  v-for="language in emailLanguage"
                                  :key="language"
                                >
                                  {{ language }}
                                </v-tab>
                              </v-tabs>

                              <v-tabs-items v-model="selectedEmailLanguage">
                                <v-tab-item
                                  v-for="item in emailLanguage"
                                  :key="item"
                                >
                                  <v-card color="basil" flat>
                                    <v-card-text>
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-select
                                            v-model="
                                              selectedCustomer[
                                                `emailTemplateAvis${item}Id`
                                              ]
                                            "
                                            class="mb-3"
                                            hide-details
                                            dense
                                            item-text="name"
                                            item-value="id"
                                            label="Avis"
                                            :items="
                                              emailTemplates.emailTemplatesAvis
                                            "
                                            outlined
                                          ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-select
                                            v-model="
                                              selectedCustomer[
                                                `emailTemplateOrderConfirmation${item}Id`
                                              ]
                                            "
                                            :items="
                                              emailTemplates.emailTemplatesOrderConfirmation
                                            "
                                            item-text="name"
                                            item-value="id"
                                            class="mb-3"
                                            hide-details
                                            dense
                                            label="Order confirmation"
                                            outlined
                                          ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-select
                                            v-model="
                                              selectedCustomer[
                                                `emailTemplateDocumentRequest${item}Id`
                                              ]
                                            "
                                            :items="
                                              emailTemplates.emailTemplatesDocumentRequest
                                            "
                                            item-text="name"
                                            item-value="id"
                                            class="mb-3"
                                            hide-details
                                            dense
                                            label="Document request"
                                            outlined
                                          ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-select
                                            v-model="
                                              selectedCustomer[
                                                `emailTemplatePaymentRequest${item}Id`
                                              ]
                                            "
                                            :items="
                                              emailTemplates.emailTemplatesPaymentRequest
                                            "
                                            item-text="name"
                                            item-value="id"
                                            class="mb-3"
                                            hide-details
                                            dense
                                            label="Payment request"
                                            outlined
                                          ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-select
                                            v-model="
                                              selectedCustomer[
                                                `emailTemplateInvoice${item}Id`
                                              ]
                                            "
                                            :items="
                                              emailTemplates.emailTemplatesInvoice
                                            "
                                            item-text="name"
                                            item-value="id"
                                            class="mb-3"
                                            hide-details
                                            dense
                                            label="Invoice"
                                            outlined
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-tab-item>
                              </v-tabs-items>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-flex>
                </td>
              </template>
            </v-data-table>
            <v-snackbar v-model="showSuccessMessage" :timeout="5000">
              {{ successMessage }}
              <template #action="{ attrs }">
                <v-btn
                  color="success"
                  v-bind="attrs"
                  @click="showSuccessMessage = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <ConfirmDialog
              v-model="showConfirmDeleteDialog"
              :is-loading="confirmDeleteDialogLoading"
              title="Delete customer"
              confirm-button-text="Confirm"
              close-button-text="Cancel"
              @cancel="selectedCustomer = null"
              @confirm="confirmDelete"
            >
              Are you sure you want to delete this customer?
            </ConfirmDialog>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ValidationObserver } from 'vee-validate';
import { ToolbarItem } from '../models/ToolbarItem';
import { DataOptions, DataTableHeader } from 'vuetify';
import {
    EcomCustomerApi,
    EcomCustomer,
    EmailTemplateApi,
    EmailTemplateSimple,
    CountryOfOriginViewModel,
    DataApi,
    Int32StringKeyValueItem,
    NoTaxType,
    EcomCustomerFile,
    EcomCustomerOverview,
    DebtorNumberApi,
} from '@/openapi';
import { emitError } from '@/event-bus';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { FooterOptions } from '@/types/types';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue.html';
import store from '@/store';
import { DataAction } from '@/store/dataModule';

interface EcomCustomerFiles {
    importPoAFile: File | null;
    importConnectionPoAFile: File | null;
    importDeferralPoAFile: File | null;
    importIoRPoAFile: File | null;
    exportPoAFile: File | null;
    transitPoAFile: File | null;
    flightHandlingPoAFile: File | null;
}

const customerApi = new EcomCustomerApi(undefined, '');
const emailapi = new EmailTemplateApi(undefined, '');
const dataApi: DataApi = new DataApi(undefined, '');
const debtorApi = new DebtorNumberApi(undefined, '');

const emits = defineEmits(['PageInfoReceived']);

const powerOfAttorenyDefaultValues: EcomCustomerFiles = {
    importPoAFile: null,
    importConnectionPoAFile: null,
    importDeferralPoAFile: null,
    importIoRPoAFile: null,
    exportPoAFile: null,
    transitPoAFile: null,
    flightHandlingPoAFile: null,
};

const days = ref<string[]>([
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
    'Last day of the month',
    '15th of the month',
]);

const languages = ref<Int32StringKeyValueItem[]>([]);
const schemes = ref<Int32StringKeyValueItem[]>([]);
const rateCards = ref<Int32StringKeyValueItem[]>([]);
const invoices = ref<Int32StringKeyValueItem[]>([]);

const expanded = ref<EcomCustomer[]>([]);
const selectedEmailLanguage = ref<string[]>();
const emailTemplates = ref<{ [key: string]: EmailTemplateSimple[] }>({});
const isLoading = ref(false);
const isSettingUpCreate = ref(false);

const emailLanguage = ref<string[]>(['DE', 'EN']);
const originCountries = ref<CountryOfOriginViewModel[]>([]);
const headers = ref<DataTableHeader[]>([
    {
        text: 'Customer name',
        align: 'start',
        value: 'customerName',
    },
    { text: 'Matchcode', value: 'matchCode' },
    { text: 'Debtor number', value: 'debtorNumber' },
]);
const showSuccessMessage = ref(false);
const successMessage = ref('');

const showConfirmDeleteDialog = ref(false);
const confirmDeleteDialogLoading = ref(false);

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});

const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const totalAmountOfItems = ref(0);

const powerOfAttorney = ref<EcomCustomerFiles>({
    ...powerOfAttorenyDefaultValues,
});

const powerOfAttorneyItems = ref([
    {
        id: 1,
        name: 'Import',
        value: 'importPoAFile',
        enabledPropName: 'importPoAEnabled',
        propName: 'importPoA',
        children: [
            {
                id: 2,
                name: 'Verbundenheit',
                value: 'importConnectionPoAFile',
                enabledPropName: 'importConnectionPoAEnabled',
                propName: 'importConnectionPoA',
            },
            {
                id: 3,
                name: 'Aufschubkonto',
                value: 'importDeferralPoAFile',
                enabledPropName: 'importDeferralPoAEnabled',
                propName: 'importDeferralPoA',
            },
            {
                id: 4,
                name: 'IoR Definition',
                value: 'importIoRPoAFile',
                enabledPropName: 'importIoRPoAEnabled',
                propName: 'importIoRPoA',
            },
        ],
    },
    {
        id: 5,
        name: 'Export',
        value: 'exportPoAFile',
        enabledPropName: 'exportPoAEnabled',
        propName: 'exportPoA',
    },
    {
        id: 6,
        name: 'Transit',
        value: 'transitPoAFile',
        enabledPropName: 'transitPoAEnabled',
        propName: 'transitPoA',
    },
    {
        id: 7,
        name: 'Flughandling',
        value: 'flightHandlingPoAFile',
        enabledPropName: 'flightHandlingPoAEnabled',
        propName: 'flightHandlingPoA',
    },
]);

const billingTypes = ref<string[]>(['Import', 'Export', 'Single positions']);
const selectedBillingType = ref(null);

const search = ref('');
const customers = ref<EcomCustomerOverview[]>([]);
const selectedCustomer = ref<EcomCustomer | null>();

const sortBy = computed(() => {
    return options.value.sortBy?.[0] ?? '';
});

const sortDesc = computed(() => {
    return options.value.sortDesc?.[0] ?? false;
});

const invoiceFooterTexts = computed(() => store.getters.invoiceFooterTexts);
const paymentTerms = computed(() => store.getters.paymentTerms);
const isNewEcomCustomer = computed(
    () => selectedCustomer.value && !selectedCustomer.value.id
);

const legacyCustomers = computed(() => store.getters.generalCustomers);

const createNewEcomCustomer = async () => {
    if (isNewEcomCustomer.value) {
        return;
    }

    isSettingUpCreate.value = true;
    const response = await debtorApi.hasUsableDebtorNumber();
    if (!response.data.hasUsable) {
        emitError(
            'No free debtor numbers are available anymore. First create a range of debtor numbers before continuing'
        );
        isSettingUpCreate.value = false;
        return;
    }

    const newCustomer = {
        id: undefined,
        matchCode: null,
        debtorNumber: null,
        customerName: null,
        customerStreet: null,
        customerStreetAdd: null,
        customerZip: null,
        customerCity: null,
        customerCountry: null,
        invoiceName: null,
        invoiceStreet: null,
        invoiceStreetAdd: null,
        invoiceZip: null,
        invoiceCity: null,
        invoiceCountry: null,
        billingEmailImportPart1: null,
        billingEmailImportPart2: null,
        billingEmailExportPart1: null,
        billingEmailExportPart2: null,
        billingEmailSinglePosPart1: null,
        billingEmailSinglePosPart2: null,
        reminderEmail: null,
        vatId: null,
        creditLimit: null,
        paymentTerms: null,
        isCustomerWithCredit: true,
        invoiceFooterTextId: null,
        noTax: true,
        invoiceRemarks: null,
        invoiceReminderLanguage: null,
        canBeGeneralCustomer: true,
        importBillingDays: [],
        importLastDayOfMonth: true,
        importFifteenthOfMonth: true,
        importTime: null,
        exportBillingDays: [],
        exportLastDayOfMonth: true,
        exportFifteenthOfMonth: true,
        exportTime: null,
        singlePosBillingDays: [],
        singlePosLastDayOfMonth: true,
        singlePosFifteenthOfMonth: true,
        singlePosTime: null,
        schemeId: null,
        ratecardId: null,
        csvInvoiceExportId: null,
        emailTemplateAvisENId: null,
        emailTemplateAvisDEId: null,
        emailTemplateOrderConfirmationENId: null,
        emailTemplateOrderConfirmationDEId: null,
        emailTemplateDocumentRequestENId: null,
        emailTemplateDocumentRequestDEId: null,
        emailTemplatePaymentRequestENId: null,
        emailTemplatePaymentRequestDEId: null,
        emailTemplateInvoiceENId: null,
        emailTemplateInvoiceDEId: null,
    } as EcomCustomer;
    customers.value.unshift(newCustomer);
    selectedCustomer.value = newCustomer;
    totalAmountOfItems.value += 1;
    expanded.value.push(selectedCustomer.value);
    powerOfAttorney.value = { ...powerOfAttorenyDefaultValues };
    isSettingUpCreate.value = false;
};

const getCustomers = async () => {
    isLoading.value = true;
    try {
        const response = await customerApi.getEcomCustomers(
            search.value,
            sortBy.value,
            sortDesc.value,
            options.value.page,
            options.value.itemsPerPage
        );
        customers.value = response.data.items ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;

        isLoading.value = false;
    } catch (e) {
        emitError('Something went wrong while retrieving the customers');
    }
};

const getEmailTemplatesInRange = async () => {
  const templateData = [
    { name: "Avis", id: 1 },
    { name: "OrderConfirmation", id: 2 },
    { name: "DocumentRequest", id: 3 },
    { name: "PaymentRequest", id: 4 },
    { name: "Invoice", id: 5 },
    { name: "PreAlert", id: 6 }
  ];

    for (let i = 0; i < templateData.length; i++) {
        const { name, id } = templateData[i];
        await getEmailTemplates(id, `emailTemplates${name}`);
    }
};

const getEmailTemplates = async (pageNumber: number, propertyName: string) => {
    isLoading.value = true;
    try {
        const response = await emailapi.getEmailTemplates(pageNumber, false);
        emailTemplates.value[propertyName] = response.data.emailTemplates ?? [];
        isLoading.value = false;
    } catch (e) {
        emitError('Something went wrong while retrieving the email templates');
    }
};

const getOriginCountries = async () => {
    dataApi
        .getOriginCountries()
        .then((result) => (originCountries.value = result.data))
        .catch((error) => {});
};

const getLanguages = async () => {
    dataApi
        .getLanguages()
        .then((result) => (languages.value = result.data))
        .catch((error) => {
            emitError('Something went wrong while retrieving the languages');
        });
};

const getSchemes = async () => {
    dataApi
        .listSchemes()
        .then((result) => (schemes.value = result.data))
        .catch((error) => {
            emitError('Something went wrong while retrieving the schemes');
        });
};

const getCsvInvoiceExports = async () => {
    dataApi
        .listCsvInvoiceExports()
        .then((result) => (invoices.value = result.data))
        .catch((error) => {
            emitError(
                'Something went wrong while retrieving the CSV invoice exports'
            );
        });
};

const getSelectedCustomer = async (customerId: number) => {
    isLoading.value = true;
    try {
        const response = await customerApi.getEcomCustomer(customerId);
        selectedCustomer.value = response.data.ecomCustomer ?? null;
    } catch (e) {
        emitError(
            'Something went wrong while retrieving the selected customer'
        );
    }
    isLoading.value = false;
};

const openCustomer = async ({
    item,
    value,
}: {
    item: EcomCustomer;
    value: boolean;
}) => {
    if (value) {
        if (item?.id) {
            await getSelectedCustomer(item.id!);
        }
    } else {
        selectedCustomer.value = null;
    }
};

const onUpdateOptions = () => {
    if (customers.value.length) {
        getCustomers();
    }
};

const saveCustomer = async () => {
  isLoading.value = true;
  try {
    const response = await customerApi.saveEcomCustomer(
      selectedCustomer.value?.id,
      selectedCustomer.value?.oldCustomerId ?? undefined,
      selectedCustomer.value?.matchCode! ?? undefined,
      selectedCustomer.value?.customerName! ?? undefined,
      selectedCustomer.value?.customerStreet! ?? undefined,
      selectedCustomer.value?.customerStreetAdd! ?? undefined,
      selectedCustomer.value?.customerZip! ?? undefined,
      selectedCustomer.value?.customerCity! ?? undefined,
      selectedCustomer.value?.customerCountry! ?? undefined,
      selectedCustomer.value?.invoiceName! ?? undefined,
      selectedCustomer.value?.invoiceStreet! ?? undefined,
      selectedCustomer.value?.invoiceStreetAdd! ?? undefined,
      selectedCustomer.value?.invoiceZip! ?? undefined,
      selectedCustomer.value?.invoiceCity! ?? undefined,
      selectedCustomer.value?.invoiceCountry! ?? undefined,
      selectedCustomer.value?.billingEmailImportPart1! ?? undefined,
      selectedCustomer.value?.billingEmailImportPart2! ?? undefined,
      selectedCustomer.value?.billingEmailExportPart1! ?? undefined,
      selectedCustomer.value?.billingEmailExportPart2! ?? undefined,
      selectedCustomer.value?.billingEmailSinglePosPart1! ?? undefined,
      selectedCustomer.value?.billingEmailSinglePosPart2! ?? undefined,
      selectedCustomer.value?.reminderEmail! ?? undefined,
      selectedCustomer.value?.vatId! ?? undefined,
      selectedCustomer.value?.creditLimit! ?? undefined,
      selectedCustomer.value?.isCustomerWithCredit! ?? undefined,
      selectedCustomer.value?.invoiceFooterTextId! ?? undefined,
      selectedCustomer.value?.noTax!,
      selectedCustomer.value?.noTaxTypeValue! ?? undefined,
      undefined,
      selectedCustomer.value?.invoiceRemarks! ?? undefined,
      selectedCustomer.value?.invoiceReminderLanguageId! ?? undefined,
      selectedCustomer.value?.amountOfDaysForAutomaticReturn!
        ? selectedCustomer.value?.amountOfDaysForAutomaticReturn!
        : 0,
      selectedCustomer.value?.amountOfDaysForAutomaticReturnExport!
        ? selectedCustomer.value?.amountOfDaysForAutomaticReturnExport!
        : 0,
      selectedCustomer.value?.canBeGeneralCustomer!,
      selectedCustomer.value?.importBillingDays!,
      selectedCustomer.value?.importTime! ?? undefined,
      selectedCustomer.value?.importPaymentTermId! ?? undefined,
      selectedCustomer.value?.exportBillingDays!,
      selectedCustomer.value?.exportTime! ?? undefined,
      selectedCustomer.value?.exportPaymentTermId! ?? undefined,
      selectedCustomer.value?.singlePosBillingDays!,
      selectedCustomer.value?.singlePosTime! ?? undefined,
      selectedCustomer.value?.singlePosPaymentTermId! ?? undefined,
      selectedCustomer.value?.importPoA?.id!,
      powerOfAttorney.value?.importPoAFile!,
      selectedCustomer.value?.importPoAEnabled,
      selectedCustomer.value?.importConnectionPoA?.id!,
      powerOfAttorney.value?.importConnectionPoAFile!,
      selectedCustomer.value?.importConnectionPoAEnabled,
      selectedCustomer.value?.importDeferralPoA?.id!,
      powerOfAttorney.value?.importDeferralPoAFile!,
      selectedCustomer.value?.importDeferralPoAEnabled!,
      selectedCustomer.value?.importIoRPoA?.id!,
      powerOfAttorney.value?.importIoRPoAFile!,
      selectedCustomer.value?.importIoRPoAEnabled!,
      selectedCustomer.value?.exportPoA?.id!,
      powerOfAttorney.value?.exportPoAFile!,
      selectedCustomer.value?.exportPoAEnabled!,
      selectedCustomer.value?.transitPoA?.id!,
      powerOfAttorney.value?.transitPoAFile!,
      selectedCustomer.value?.transitPoAEnabled!,
      selectedCustomer.value?.flightHandlingPoA?.id!,
      powerOfAttorney.value?.flightHandlingPoAFile!,
      selectedCustomer.value?.flightHandlingPoAEnabled!,
      selectedCustomer.value?.schemeId ?? undefined,
      selectedCustomer.value?.ratecardId ?? undefined,
      selectedCustomer.value?.csvInvoiceExportId ?? undefined,
      selectedCustomer.value?.emailTemplateAvisENId ?? undefined,
      selectedCustomer.value?.emailTemplateAvisDEId ?? undefined,
      selectedCustomer.value?.emailTemplateOrderConfirmationENId ?? undefined,
      selectedCustomer.value?.emailTemplateOrderConfirmationDEId ?? undefined,
      selectedCustomer.value?.emailTemplateDocumentRequestENId ?? undefined,
      selectedCustomer.value?.emailTemplateDocumentRequestDEId ?? undefined,
      selectedCustomer.value?.emailTemplatePaymentRequestENId ?? undefined,
      selectedCustomer.value?.emailTemplatePaymentRequestDEId ?? undefined,
      selectedCustomer.value?.emailTemplateInvoiceENId ?? undefined,
      selectedCustomer.value?.emailTemplateInvoiceDEId ?? undefined,
    );

        showSuccessMessage.value = true;
        successMessage.value = 'All changes have been saved for this customer';
        selectedCustomer.value = null;
        getCustomers();
    } catch (e) {
        emitError('Something went wrong while saving the customer');
    }
    isLoading.value = false;
};

const refresh = () => {
    getCustomers();
};

const showConfirmDelete = () => {
    showConfirmDeleteDialog.value = true;
};

const deleteCustomer = async (id: number) => {
    if (!id) {
        customers.value.splice(1, 1);
        selectedCustomer.value = null;
        return;
    }

    try {
        await customerApi.deleteEcomCustomer(id);
        await getCustomers();
        showSuccessMessage.value = true;
        successMessage.value = 'Customer has been deleted';
    } catch {
        emitError('Something went wrong while deleting the customer');
    }
};

const confirmDelete = async () => {
    confirmDeleteDialogLoading.value = true;
    await deleteCustomer(selectedCustomer.value?.id!);
    confirmDeleteDialogLoading.value = false;
    showConfirmDeleteDialog.value = false;
    selectedCustomer.value = null;
    await getCustomers();
};

const getFileData = (item: any) => {
    return selectedCustomer.value![
        item.propName as keyof EcomCustomer
    ] as EcomCustomerFile;
};

const removeExistingFile = (
    poaPropName: string,
    selectedCustomerPropName: string
) => {
    powerOfAttorney.value[poaPropName as keyof EcomCustomerFiles] = null;
    (
        selectedCustomer.value![
            selectedCustomerPropName as keyof EcomCustomer
        ] as EcomCustomerFile
    ).id = undefined;
};

watch(
    () => selectedCustomer.value?.emailTemplateAvisDEId,
    (
        newValue: number | null | undefined,
        oldValue: number | null | undefined
    ) => {
        if (selectedCustomer.value) {
            selectedCustomer.value.emailTemplateAvisENId = newValue;
        }
    }
);

watch(
    () => selectedCustomer.value?.emailTemplateAvisENId,
    (
        newValue: number | null | undefined,
        oldValue: number | null | undefined
    ) => {
        if (selectedCustomer.value) {
            selectedCustomer.value.emailTemplateAvisDEId = newValue;
        }
    }
);

onBeforeMount(async () => {
    await Promise.all([
        getCustomers(),
        getEmailTemplatesInRange(),
        getOriginCountries(),
        getLanguages(),
        getSchemes(),
        getCsvInvoiceExports(),
    ]);

    store.dispatch(DataAction.FetchInvoiceFooterTexts);
    store.dispatch(DataAction.FetchPaymentTerms);
    store.dispatch(DataAction.FetchGeneralCustomers);
});

var toolbarButtons: ToolbarItem[] = [
    {
        callback: () => refresh(),
        icon: 'mdi-refresh',
        tooltipText: 'Refresh overview',
    },
];
emits('PageInfoReceived', 'Customer Service Tool', toolbarButtons);
</script>

<style scoped lang="scss">
.poa {
    display: flex;
    align-items: center;
}

.existing-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0 22px;
    gap: 10px;

    &__name {
        margin-left: 35px;
        font-weight: 600;
    }
}
</style>
