<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Request shipments where a consignee didn’t reply
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <DatePicker
                v-model="form.fromDate"
                label="From date"
              ></DatePicker>
            </v-col>
            <v-col>
              <DatePicker
                v-model="form.tillDate"
                label="Till date"
              ></DatePicker>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isFormValid || loading"
          text
          @click="downloadExcelFile()"
        >
          Download Excel file</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import DatePicker from "@/components/editors/DatePicker.vue.html";
import {
  FrequentActionsApi,
  ShipmentsConsigneeNotRepliesFromModel,
} from "@/openapi";
import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
import { emitError } from "@/event-bus";
interface IProps {
  show: boolean;
}
const api = new FrequentActionsApi(undefined, "");
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);

const loading = ref(false);
const defaultForm = {
  fromDate: "",
  tillDate: "",
};
const form = ref<ShipmentsConsigneeNotRepliesFromModel>(
  structuredClone(defaultForm),
);
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const closeDialog = () => {
  loading.value = false;
  form.value = structuredClone(defaultForm);
  emit("close");
};

const isFormValid = computed(() => {
  return !!form.value.fromDate && !!form.value.tillDate;
});

const downloadExcelFile = () => {
  loading.value = true;
  api
    .shipmentsConsigneeNotReplies(form.value, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = getFileNameFromContentDispositionHeader(response);
      downloadFile(response.data, fileName!);
      closeDialog();
    })
    .catch(() => {
      emitError("Something went wrong while request shipments.");
      loading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
