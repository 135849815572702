<template>
  <div class="v-card__text shipment-general">
    <v-row>
      <v-col cols="4">
        <GeneralReferences :detail.sync="detail"></GeneralReferences>
      </v-col>
      <v-col cols="4">
        <GeneralShipmentInfo :detail.sync="detail"></GeneralShipmentInfo>
      </v-col>
      <v-col cols="4">
        <GeneralOrderDetails :detail.sync="detail"></GeneralOrderDetails>
      </v-col>
      <v-col cols="4">
        <GeneralShipperAddress :detail.sync="detail"></GeneralShipperAddress>
      </v-col>

      <v-col cols="4">
        <GeneralInvoiceAddress :detail.sync="detail"></GeneralInvoiceAddress
      ></v-col>

      <v-col cols="4">
        <GeneralDeliveryAddress :detail.sync="detail"></GeneralDeliveryAddress>
      </v-col>

      <v-col cols="8">
        <GeneralMilestones :detail.sync="detail"></GeneralMilestones>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3 remarks-card">
          <v-toolbar class="elevation-0" dense>
            <v-card-title>Remarks</v-card-title>
          </v-toolbar>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="detail.references.remark"
                label="remarks"
                outlined
                hide-details
                auto-grow
                row-height="70"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-btn
          class="mr-4"
          target="_blanc"
          :href="detail.importFileLocation"
          :disabled="!detail.importFileLocation"
          color="btn success mr-4"
          elevation="2"
          ><v-icon color="white">mdi-download</v-icon>Download Import
          File</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { ValidationObserver } from "vee-validate";
import { ref } from "vue";
import GeneralReferences from "./GeneralReferences.vue";
import GeneralShipmentInfo from "./GeneralShipmentInfo.vue";
import GeneralOrderDetails from "./GeneralOrderDetails.vue";
import GeneralShipperAddress from "./GeneralShipperAddress.vue";
import GeneralInvoiceAddress from "./GeneralInvoiceAddress.vue";
import GeneralDeliveryAddress from "./GeneralDeliveryAddress.vue";

import GeneralMilestones from "./GeneralMilestones.vue";
import { ShipmentDetailViewModel } from "@/openapi";

interface IProps {
  detail: ShipmentDetailViewModel;
}

const props = defineProps<IProps>();

const dateModel = ref<string | null>(null);
const placeHolderModel = ref("");
</script>

<style lang="scss">
.shipment-general .v-card {
  height: 100%;
}
</style>
