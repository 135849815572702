import { render, staticRenderFns } from "./ShipmentInfoDimensionsDialog.vue?vue&type=template&id=574d50ab"
import script from "./ShipmentInfoDimensionsDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./ShipmentInfoDimensionsDialog.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports