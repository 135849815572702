<template>
  <v-card>
    <validation-observer v-slot="{ invalid }">
      <v-card-title class="text-h5 grey lighten-2"> Documents </v-card-title>
      <v-col
        ><v-simple-table class="documents-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="col-4">Filename</th>
                <th class="col-4">Entry date</th>
                <th class="col-4">Type</th>
              </tr>
            </thead>
            <tbody :loading="saving">
              <tr v-for="doc in files">
                <td class="col-4">
                  <v-text-field
                    v-model="doc.fileName"
                    :disabled="saving"
                    label="Name"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
                <td class="col-4">
                  {{ docDate(doc) }}
                </td>
                <td class="col-4">
                  <ValidationProvider
                    name="Type"
                    rules="required"
                    v-slot="{ errors }"
                    immediate
                  >
                    <v-select
                      v-model="doc.type"
                      :disabled="saving"
                      :items="dataStore.documentTypeCustoms"
                      item-text="value"
                      item-value="key"
                      hide-details
                      dense
                      label="Type"
                      outlined
                      class="max-200"
                    ></v-select>
                  </ValidationProvider>
                </td>
              </tr>
            </tbody>
          </template> </v-simple-table
      ></v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="saving" text @click="closeDialog()">
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving || invalid"
          :loading="saving"
          text
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useDataStore } from "@/stores/data-store";
import { ShipmentDetailDocumentsModel } from "@/openapi";
import moment from "moment";

const dataStore = useDataStore();

interface IProps {
  files: ShipmentDetailDocumentsModel[];
  saving: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["close-dialog", "save"]);
// const saving = ref<boolean>(false);

function docDate(doc: ShipmentDetailDocumentsModel) {
  return doc.id == 0 ? "" : moment(doc.entryDate).format("YYYY-MM-DD HH:mm:ss");
}

const closeDialog = () => {
  emit("close-dialog");
};

const save = () => {
  emit("save");
};
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
</style>
