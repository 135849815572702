<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Upload file to Dakosy interface importer
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                label="Select file to upload"
                accept="application/pdf, .xml"
                dense
                hide-details
                outlined
              ></v-file-input>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!isFormValid || loading" text @click="uploadFile()">
          Upload file PDF/XML</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { FrequentActionsApi } from "@/openapi";
import { emitError, emitSuccess } from "@/event-bus";
interface IProps {
  show: boolean;
}
const api = new FrequentActionsApi(undefined, "");
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);
const loading = ref(false);
const file = ref<File | null>(null);

const isFormValid = computed(() => {
  return file.value !== null;
});

const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const closeDialog = () => {
  loading.value = false;
  file.value = null;
  emit("close");
};

const uploadFile = () => {
  loading.value = true;
  if (file.value) {
    api
      .uploadFileToDakosy(file.value)
      .then(() => {
        emitSuccess("File uploaded successfully.");
        closeDialog();
      })
      .catch((error) => {
        emitError(error);
        loading.value = false;
      });
  }
};
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
