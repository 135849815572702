<template>
  <div class="v-card__text">
    <v-row>
      <v-col cols="6">
        <v-card class="pa-3 dpd-card">
          <v-toolbar class="elevation-0" dense>
            <v-card-title>DPD</v-card-title>
          </v-toolbar>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.sealNumber"
                label="Seal number"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.mrn"
                label="MRN"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.mpsComplete"
                label="MPS complete"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.receiverType"
                label="Reciever type"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.reasonForExport"
                label="Reason for export"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.content"
                label="Content"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.value"
                label="Value"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.grossWeight"
                label="Gross weight"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.customTransportCost"
                label="Custom transport cost"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.currencyTransportCost"
                label="Currency transport cost"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.invoiceNumber"
                label="Invoice number"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.mrnExport"
                label="MRN export"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.arbeitsNummer"
                label="Arbeitsnummer"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.mawbExport"
                label="MAWB (Export)"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.gatewayExport"
                label="Gateway (Export)"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.sealnumberExport"
                label="Sealnumber (Export)"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.registrationNumberExport"
                label="Registration number (Export)"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.senderInvoicePropNumberExport"
                label="Sender Invoice Prop Number (Export)"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpd.receiverType"
                label="Receiver type"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-3 dpd-card">
          <v-toolbar class="elevation-0" dense>
            <v-card-title>DPD Return Conso Data</v-card-title>
          </v-toolbar>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderContact"
                label="Sender contact"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderPhone"
                label="Sender phone"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderEmail"
                label="Sender email"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderBusiness"
                label="Sender business"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderEori"
                label="Sender EORI"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderVatNo"
                label="Sender VAT no."
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderTaxEidType"
                label="Sender Tax EID type"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderTaxEidValue"
                label="Sender Tax EID value"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.receiverContact"
                label="Receiver contact"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.receiverVatNo"
                label="Receiver VAT no."
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.receiverTaxEidType"
                label="Receiver Tax EID type"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.receiverTaxEidValue"
                label="Receiver Tax EID value"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.senderDepot"
                label="Sender depot"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.receiverDepot"
                label="Receiver depot"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.originalIncoterm"
                label="Original incoterm"
                outlined
                dense
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.mpsIdCcKey"
                label="MPS ID CC Key"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.parceltype"
                label="Parceltype"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.deliveryModificationAllow"
                label="Delivery modification allow"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="estimatedDepartureDate"
                label="Estimated departure date"
                outlined
                dense
                hide-details
              ></DatePicker>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="estimatedDepartureTime"
                label="Estimated departure time"
                type="time"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.exportGatewayDepotCode"
                label="Export gateway depot code"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.importGatewayDepotCode"
                label="Import gateway depot code"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.operationCode"
                label="Operation code"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.lorryNumber"
                label="Lorry number"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="detail.dpdConsoData.creationDate"
                label="Creation date"
                outlined
                dense
                hide-details
                readonly
              ></DatePicker>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.shipmentOperationCode"
                label="Shipment operation code"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.originalPhoneNumber"
                label="Original phone number"
                outlined
                dense
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.originalEmailAddress"
                label="Original email address"
                outlined
                dense
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="detail.dpdConsoData.originalShipMrn"
                label="Original Ship MRN"
                outlined
                dense
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import moment from "moment";
import { ShipmentDetailViewModel } from "@/openapi";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();

const estimatedDepartureDate = computed({
  get() {
    if (!props.detail.dpdConsoData.estimatedDepartureDate) {
      return "";
    }
    return moment(props.detail.dpdConsoData.estimatedDepartureDate).format(
      "YYYY-MM-DD",
    );
  },
  set(value) {
    if (props.detail.dpdConsoData.estimatedDepartureDate) {
      props.detail.dpdConsoData.estimatedDepartureDate = `${value}T${props.detail.dpdConsoData.estimatedDepartureDate.split("T")[1]}`;
    } else {
      props.detail.dpdConsoData.estimatedDepartureDate = `${value}T00:00:00`;
    }
  },
});

const estimatedDepartureTime = computed({
  get() {
    if (!props.detail.dpdConsoData.estimatedDepartureDate) {
      return "";
    }
    return moment(props.detail.dpdConsoData.estimatedDepartureDate).format(
      "HH:mm",
    );
  },
  set(value) {
    if (props.detail.dpdConsoData.estimatedDepartureDate) {
      props.detail.dpdConsoData.estimatedDepartureDate = `${props.detail.dpdConsoData.estimatedDepartureDate.split("T")[0]}T${value}`;
    } else {
      const date = Date.now();
      const day = `${date.getDate()}`.padStart(2, "0");
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const year = date.getFullYear();
      props.detail.dpdConsoData.estimatedDepartureDate = `${year}-${month}-${day}T${value}`;
    }
  },
});
</script>
