<template>
  <v-card>
    <v-container fluid>
      <v-row>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="0">
          <div class="action-button" @click="selectedDialog = 0">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-folder-zip
              </v-icon>
            </div>
            <div align="center" class="text-button">
              Get taxforms for parcel ids
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="1">
          <div class="action-button" @click="selectedDialog = 1">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">
              Get HAWB numbers from invoice numbers
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="2">
          <div class="action-button" @click="selectedDialog = 2">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">Recreate invoices</div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="3">
          <div class="action-button" @click="selectedDialog = 3">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">
              Check same sender / same consignee for export
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="4">
          <div class="action-button" @click="selectedDialog = 4">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">
              Check uncleared shipments older than xx hours
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="5">
          <div class="action-button" @click="selectedDialog = 5">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">
              Request shipments where a consignee didn’t reply
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="6">
          <div class="action-button" @click="selectedDialog = 6">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">
              Request shipment data for HAWB numbers
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="7">
          <div class="action-button" @click="selectedDialog = 7">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">Set milestones</div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="8">
          <div class="action-button" @click="selectedDialog = 8">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">Show overdue shipments</div>
          </div>
        </v-col>
        <v-col cols="3" class="pa-3 pt-10 pb-5" dialog="9">
          <div class="action-button" @click="selectedDialog = 9">
            <div align="center" class="yellow darken-3">
              <v-icon class="white--text py-3" style="font-size: 36px">
                mdi-text-box-check
              </v-icon>
            </div>
            <div align="center" class="text-button">
              Upload file to Dakosy interface importer
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- DIALOG: 0 -->
    <ParcelsTaxformsDialog
      :show="showDialogParcelFormTax"
      @close="closeDialog"
    ></ParcelsTaxformsDialog>
    <!-- DIALOG: 1 -->
    <HawbNumbersFromInvoiceDialog
      :show="showDialogHawbNumbersFromInvoice"
      @close="closeDialog"
    ></HawbNumbersFromInvoiceDialog>
    <!-- DIALOG: 2 -->
    <RecreateInvoicesDialog
      :show="showDialogRecreateInvoices"
      @close="closeDialog"
    ></RecreateInvoicesDialog>
    <!-- DIALOG: 3 -->
    <SameSenderCheckDialog
      :show="showDialogSameSenderCheck"
      @close="closeDialog"
    ></SameSenderCheckDialog>
    <!-- DIALOG: 4 -->
    <CheckUnclearedShipmentsDialog
      :show="showDialogCheckUnclearedShipments"
      @close="closeDialog"
    ></CheckUnclearedShipmentsDialog>
    <!-- DIALOG: 5 -->
    <NotRepliedShipmentsDialog
      :show="showDialogNotRepliedShipments"
      @close="closeDialog"
    ></NotRepliedShipmentsDialog>
    <!-- DIALOG: 6 -->
    <ShipmentDataDialog
      :show="showDialogShipmentData"
      @close="closeDialog"
    ></ShipmentDataDialog>
    <!-- DIALOG: 7 -->
    <SetMilestonesDialog
      :show="showDialogSetMilestones"
      @close="closeDialog"
    ></SetMilestonesDialog>
    <!-- DIALOG: 8 -->
    <OverDueShipmentsDialog
      :show="showDialogOverDueShipments"
      @close="closeDialog"
    ></OverDueShipmentsDialog>
    <!-- DIALOG: 9 -->
    <UploadFileDakosyImporterDialog
      :show="showDialogUploadFileDakosyImporter"
      @close="closeDialog"
    ></UploadFileDakosyImporterDialog>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import ParcelsTaxformsDialog from "./components/dialogs/ParcelsTaxformsDialog.vue";
import HawbNumbersFromInvoiceDialog from "./components/dialogs/HawbNumbersFromInvoiceDialog.vue";
import RecreateInvoicesDialog from "./components/dialogs/RecreateInvoicesDialog.vue";
import SameSenderCheckDialog from "./components/dialogs/SameSenderCheckDialog.vue";
import CheckUnclearedShipmentsDialog from "./components/dialogs/CheckUnclearedShipmentsDialog.vue";
import NotRepliedShipmentsDialog from "./components/dialogs/NotRepliedShipmentsDialog.vue";
import ShipmentDataDialog from "./components/dialogs/ShipmentDataDialog.vue";
import SetMilestonesDialog from "./components/dialogs/SetMilestonesDialog.vue";
import OverDueShipmentsDialog from "./components/dialogs/OverDueShipmentsDialog.vue";
import UploadFileDakosyImporterDialog from "./components/dialogs/UploadFileDakosyImporterDialog.vue";

const selectedDialog = ref<number | null>(null);
// DIALOG: 0
const showDialogParcelFormTax = computed({
  get() {
    return selectedDialog.value === 0;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 1
const showDialogHawbNumbersFromInvoice = computed({
  get() {
    return selectedDialog.value === 1;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 2
const showDialogRecreateInvoices = computed({
  get() {
    return selectedDialog.value === 2;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 3
const showDialogSameSenderCheck = computed({
  get() {
    return selectedDialog.value === 3;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 4
const showDialogCheckUnclearedShipments = computed({
  get() {
    return selectedDialog.value === 4;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 5
const showDialogNotRepliedShipments = computed({
  get() {
    return selectedDialog.value === 5;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 6
const showDialogShipmentData = computed({
  get() {
    return selectedDialog.value === 6;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 7
const showDialogSetMilestones = computed({
  get() {
    return selectedDialog.value === 7;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 8
const showDialogOverDueShipments = computed({
  get() {
    return selectedDialog.value === 8;
  },
  set() {
    selectedDialog.value = null;
  },
});
// DIALOG: 9
const showDialogUploadFileDakosyImporter = computed({
  get() {
    return selectedDialog.value === 9;
  },
  set() {
    selectedDialog.value = null;
  },
});

const closeDialog = () => {
  selectedDialog.value = null;
};
</script>

<style scoped>
.action-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-application .action-button:hover .yellow.darken-3 {
  opacity: 0.85;
}

.action-button .text-button {
  flex-grow: 1;
  border-left: solid rgba(0, 0, 0, 0.2) 1px;
  border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
  border-right: solid rgba(0, 0, 0, 0.2) 1px;
  line-height: 1.5rem;
  padding: 8px;
  align-content: center;
}
</style>
