<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Check uncleared shipments older than xx hours
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.costumer"
                hide-details
                dense
                item-text="shortName"
                :items="dataStore.generalCustomers"
                item-value="id"
                label="General customer"
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.hours"
                hide-details
                dense
                label="Hours"
                outlined
                type="number"
                step="1"
                min="1"
              ></v-text-field>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isFormValid || loading"
          text
          @click="downloadExcelFile()"
        >
          Download Excel file</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { FrequentActionsApi, CheckUnclearedFromModel } from "@/openapi";
import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
import { useDataStore } from "@/stores/data-store";
import { emitError } from "@/event-bus";
interface IProps {
  show: boolean;
}
const props = defineProps<IProps>();
const dataStore = useDataStore();
const api = new FrequentActionsApi(undefined, "");
const emit = defineEmits(["close"]);

const loading = ref(false);
const defaultForm = {
  costumer: 0,
  hours: 1,
};
const form = ref<CheckUnclearedFromModel>(structuredClone(defaultForm));
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    loading.value = false;
    emit("close");
  },
});

const closeDialog = () => {
  loading.value = false;
  form.value = structuredClone(defaultForm);
  emit("close");
};

const isFormValid = computed(() => {
  return form.value.costumer > 0 && form.value.hours > 0;
});

const downloadExcelFile = () => {
  loading.value = true;
  api
    .checkUnclearedShipments(form.value, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = getFileNameFromContentDispositionHeader(response);
      downloadFile(response.data, fileName!);
      closeDialog();
    })
    .catch(() => {
      emitError("Something went wrong while checking same sender/consignee.");
      loading.value = false;
    });
};

onBeforeMount(() => {
  dataStore.fetchGeneralCustomers();
});
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
