<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      Invoice details
    </v-card-title>
    <v-col>
      <v-data-table
        :headers="invoiceDetailsHeaders"
        :items="detail.invoices"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.invoiceNumber }}</td>
            <td>{{ item.invoiceKind }}</td>
            <td>
              {{ formatDateTime(item.invoiceDate) }}
            </td>
            <td>{{ item.atcNumber }}</td>
            <td>
              {{ formatDateTimeWithTime(item.employeeSentDate) }}
            </td>
            <td>€ {{ item.eustCharged }}</td>
            <td>€ {{ item.zollCharged }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import moment from "moment";

interface IProps {
  detail: ShipmentDetailViewModel;
  openDialog: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["update:open-dialog"]);

const invoiceDetailsHeaders = ref([
  { text: "Invoice number", value: "invoiceNumber" },
  { text: "Kind of invoice", value: "kindOfInvoice" },
  { text: "Invoice date", value: "invoiceDate" },
  { text: "ATC number", value: "atcNumber" },
  { text: "Employee sent date", value: "employeeSentDate" },
  { text: "EUST charged", value: "eustCharged" },
  { text: "Zoll charged", value: "zollCharged" },
]);

const invoiceDetailsItems = ref([
  {
    invoiceNumber: "INV001",
    kindOfInvoice: "Invoice",
    invoiceDate: "2024-03-11",
    atcNumber: "ATC123",
    employeeSentDate: "2024-03-11T12:30:00",
    eustCharged: 100.0,
    zollCharged: 50.0,
  },
]);

const closeDialog = () => {
  emit("update:open-dialog", false);
};

const formatDateTime = (dateTime: Date) => {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }

  return moment(dateTime).format("dd.MM.yyyy");
};

const formatDateTimeWithTime = (dateTime: Date) => {
  if (dateTime === null || dateTime === undefined) {
    return "";
  }

  return moment(dateTime).format("dd.MM.yyyy HH:mm");
};
</script>
