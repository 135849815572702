<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2"> Parcels </v-card-title>
    <v-col>
      <v-data-table
        :headers="parcelsHeaders"
        :items-per-page="10"
        :items="items"
        :options.sync="options"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <ValidationObserver
              v-for="item in items"
              :key="item.id"
              v-slot="{ invalid, dirty, validateWithInfo }"
              :ref="'observer-' + item.id"
              slim
            >
              <tr>
                <td>
                  <ValidationProvider name="parcelNumber" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.parcelNumber"
                      placeholder="parcelNumber"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="weight" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.weight"
                      placeholder="weight"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="dimensions" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.dimensions"
                      placeholder="dimensions"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="cost" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.cost"
                      placeholder="Height"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="currency" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.currency"
                      placeholder="currency"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="hubUk">
                    <v-checkbox class="my-2" v-model="item.hubUk" hide-details>
                    </v-checkbox>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider
                    name="hubUkDateChecked"
                    rules="required"
                    slim
                  >
                    <EditableTextField
                      class="my-2"
                      v-model="item.hubUkDateChecked"
                      placeholder="hubUkDateChecked"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="hubDe">
                    <v-checkbox class="my-2" v-model="item.hubDe" hide-details>
                    </v-checkbox>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider
                    name="hubUkDateChecked"
                    rules="required"
                    slim
                  >
                    <EditableTextField
                      class="my-2"
                      v-model="item.hubDeDateChecked"
                      placeholder="hubDeDateChecked"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <div class="d-flex justify-end">
                    <v-btn
                      class="mr-2"
                      color="success"
                      :disabled="invalid || !dirty || item.isDeleting"
                      :loading="item.isSaving"
                      small
                      @click="onSave(item, validateWithInfo)"
                      ><v-icon left>mdi-floppy</v-icon>Save</v-btn
                    >
                  </div>
                </td>
              </tr>
            </ValidationObserver>
          </tbody>
        </template>
      </v-data-table>
    </v-col>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="closeDialog()" color="primary" text> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { useCrudPage } from "@/composables/crudPage";
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { DataOptions, DataTableHeader } from "vuetify";
import {
  ShipmentDetailViewModel,
  ShipmentDetailParcelModel,
  ShipmentDetailApi,
} from "@/openapi";
import EditableTextField from "@/components/EditableTextField.vue";

interface IProps {
  detail: ShipmentDetailViewModel;
}

const props = defineProps<IProps>();
const emit = defineEmits(["update:open-dialog", "update:detail"]);
const api = new ShipmentDetailApi(undefined, "");

const defaultParcel: ShipmentDetailParcelModel = {
  id: 0,
  parcelNumber: "",
  weight: 0,
  dimensions: "",
  currency: "",
  cost: 0,
  hubUk: false,
  hubUkDateChecked: null,
  hubDe: false,
  hubDeDateChecked: null,
};

const closeDialog = () => {
  emit("update:open-dialog", false);
};

interface EnrichedShipmentParcelsModel extends ShipmentDetailParcelModel {
  isSaving: boolean;
}

const { items, disableNewItemButton, isLoading, isNewItem } =
  useCrudPage<EnrichedShipmentParcelsModel>(
    { ...defaultParcel, isSaving: false },
    "id",
    0,
  );

const isSaving = ref(false);

const parcelsHeaders = ref<DataTableHeader[]>([
  { text: "Parcelnumber", value: "parcelNumber" },
  { text: "Weight", value: "weight" },
  { text: "Dimensions", value: "dimentions" },
  { text: "Cost", value: "cost" },
  { text: "Currency", value: "currency" },
  { text: "HUB UK", value: "hubUk" },
  { text: "HUB UK date checked", value: "hubUkDateChecked" },
  { text: "HUB DE", value: "hubDe" },
  { text: "HUB DE date checked", value: "hubDeDateChecked" },
  { text: " ", value: "actions", sortable: false, width: "250px" },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const loadItems = async () => {
  isLoading.value = true;

  try {
    items.value =
      props.detail.parcels?.map((item) => ({
        ...item,
        isSaving: false,
      })) ?? [];
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while retrieving the keywords",
    );
  }
  isLoading.value = false;
};

const onSave = async (
  item: EnrichedShipmentParcelsModel,
  validate: () => Promise<boolean>,
) => {
  const isValid = await validate();
  if (!isValid) {
    return;
  }
  isSaving.value = true;
  item.isSaving = true;

  await saveCustomsKeyword(item);
};

const saveCustomsKeyword = async (item: EnrichedShipmentParcelsModel) => {
  isSaving.value = true;
  item.isSaving = true;

  const response = await api.saveShipmentDetsailParcel(item);
  item.id = response.data.id;
  props.detail.parcels = items.value;
  emit("update:detail", props.detail);
  emitSuccess("Successfully saved the keyword");
  if (isNewItem(item)) {
    disableNewItemButton.value = false;
  }

  isSaving.value = false;
  item.isSaving = false;
};

onBeforeMount(async () => {
  await loadItems();
});
</script>
