<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Set milestones
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-textarea
                v-model="ids"
                name="Enter invoice numbers"
                hide-details
                dense
                label="Invoice numbers"
                outlined
                :loading="loading"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="form.milestoneId"
                :items="dataStore.milestoneCustomsHandling"
                item-text="milestoneName"
                item-value="milestoneId"
                hide-details
                dense
                label="Milestone"
                outlined
              ></v-select>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :disabled="!isFormValid || loading"
          text
          @click="setMilestones()"
        >
          Set milestones</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { FrequentActionsApi, SetMilestonesFromModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";
import { emitError, emitSuccess } from "@/event-bus";
interface IProps {
  show: boolean;
}
const api = new FrequentActionsApi(undefined, "");
const dataStore = useDataStore();
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);

const loading = ref(false);
const defaultForm = {
  hawbNumbers: [],
  milestoneId: 0,
};
const form = ref<SetMilestonesFromModel>(structuredClone(defaultForm));
const ids = ref("");
const idsist = computed(() =>
  ids.value.split("\n").filter((item) => item.trim() !== ""),
);
const hasIds = computed(() => ids.value.length > 0);

const isFormValid = computed(() => {
  return hasIds.value && form.value.milestoneId > 0;
});

const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const closeDialog = () => {
  loading.value = false;
  form.value = structuredClone(defaultForm);
  ids.value = "";
  emit("close");
};

const setMilestones = () => {
  loading.value = true;
  form.value.hawbNumbers = idsist.value;
  api
    .setMilestones(form.value, {
      responseType: "blob",
    })
    .then(() => {
      emitSuccess("Milestones set successfully.");
      closeDialog();
    })
    .catch(() => {
      emitError("Something went wrong while setting milestones.");
      loading.value = false;
    });
};

onBeforeMount(async () => {
  await dataStore.fetchMilestoneCustomsHandling();
});
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
