import moment from "moment";

export const formatDateTimeWithTime = (
  dateTime: string | null | undefined,
  format: string | null | undefined,
) => {
  if (format === null || format === undefined) {
    format = "DD.MM.YYYY HH:mm";
  }
  if (dateTime === null || dateTime === undefined) {
    return "";
  }
  return moment(dateTime).format(format);
};

export const formatDate = (
  dateTime: string | null | undefined,
  format: string | null | undefined,
) => {
  if (!format) {
    format = "DD.MM.YYYY";
  }
  if (dateTime === null || dateTime === undefined) {
    return "";
  }
  return moment(dateTime).format(format);
};
