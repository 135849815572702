<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
      height="calc(100vh - 295px)"
      fixed-header
      fixed-footer
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.fileName="{ item }">
        <v-btn
          :href="
            '/CustomsPaperworkHandling/DownloadDocument?paperworkId=' + item.id
          "
          small
          color="success"
          class="v-btn--flexible text-center"
        >
          <v-icon left>mdi-download</v-icon>
          {{ item.fileName }}
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn small color="success" @click="allocate(item)">Allocate</v-btn>
        <v-btn
          small
          color="error"
          class="ms-4"
          @click.stop="showDeleteItemDialog(item)"
          >Delete</v-btn
        >
      </template>
    </v-data-table>

    <SetHawbNumberDialog
      v-model="showDialog"
      :is-loading="dialogLoading"
      @confirm="onHawbNumberSet"
    >
    </SetHawbNumberDialog>

    <v-dialog
      :value="true"
      v-if="showDeleteConfirmationDialog && deleteItem"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text
          >Are you sure you want to delete item {{ deleteItem.Name }}? This
          can't be undone.</v-card-text
        >
        <v-card-actions>
          <v-btn text @click="cancelDeleteItem">Cancel</v-btn>
          <v-spacer />
          <v-btn color="error" text @click="confirmDeleteItem">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { CustomsDiscrepanciesApi, UnallocatedPaperwork } from "@/openapi";
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { FooterOptions } from "@/types/types";
import { emitError } from "@/event-bus";
import { SetHawbNumberForm } from "./dialogs/SetHawbNumberDialog";
import SetHawbNumberDialog from "./dialogs/SetHawbNumberDialog.vue.html";
import { ToolbarItem } from "@/models/ToolbarItem";

interface DeleteItem {
  Name: string;
  Id: number;
}

const api = new CustomsDiscrepanciesApi(undefined, "");
const loading = ref(false);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions: FooterOptions = {
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100],
  disablePagination: false,
};

const items = ref<UnallocatedPaperwork[]>([]);
const currentItem = ref<UnallocatedPaperwork | null>(null);
const totalAmountOfItems = ref(0);

const showDialog = ref(false);
const dialogLoading = ref(false);

const showDeleteConfirmationDialog = ref(false);
const deleteItem = ref<DeleteItem | null>(null);

const headers = ref<DataTableHeader[]>([
  {
    text: "Filename/download",
    value: "fileName",
    sortable: false,
  },
  {
    text: "Create date",
    value: "date",
    sortable: false,
  },
  {
    width: "250px",
    text: "Actions",
    value: "actions",
    sortable: false,
  },
]);

watch(
  () => options.value,
  (newVal: DataOptions, oldVal: DataOptions) => {
    getUnallocatedPaperwork(options.value.page);
  },
);

const getUnallocatedPaperwork = async (page: number) => {
  loading.value = true;
  try {
    const response = await api.getUnallocatedPaperwork(
      page,
      options.value.itemsPerPage,
    );

    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError(
      "Something went wrong while retrieving the unallocated paperwork.",
    );
  }

  loading.value = false;
};

const allocate = (item: UnallocatedPaperwork) => {
  currentItem.value = item;
  showDialog.value = true;
};

const reloadUnallocatedPaperwork = async () => {
  options.value.page == 1
    ? await getUnallocatedPaperwork(options.value.page)
    : (options.value.page = 1);
};

const onHawbNumberSet = async ({ hawbNumber }: SetHawbNumberForm) => {
  dialogLoading.value = true;
  try {
    if (currentItem.value === null) {
      emitError(...["Current item is null. This shouldn't happen."]);
      return;
    }

    await api.allocatePaperwork({
      hawbNumber: hawbNumber,
      paperworkId: currentItem.value?.id,
    });
    await getUnallocatedPaperwork(options.value.page);

    showDialog.value = false;
  } catch (e: any) {
    let errorMessages: string[] = [
      "Something went while allocating the paperwork to a shipment",
    ];

    if (e?.response?.data?.errorMessages?.length) {
      errorMessages = e.response.data.errorMessages;
    }
    emitError(...errorMessages);
  }

  dialogLoading.value = false;
};

const showDeleteItemDialog = (item: UnallocatedPaperwork) => {
  deleteItem.value = {
    Name: item.fileName,
    Id: item.id,
  };
  showDeleteConfirmationDialog.value = true;
};

const cancelDeleteItem = () => {
  showDeleteConfirmationDialog.value = false;
  deleteItem.value = null;
};

const confirmDeleteItem = async () => {
  try {
    if (deleteItem.value === null) {
      emitError(...["Delete item is null. This shouldn't happen."]);
      return;
    }

    await api.deleteUnallocatedPaperwork(deleteItem.value.Id);
    await getUnallocatedPaperwork(options.value.page);
  } catch (e: any) {
    let errorMessages: string[] = [
      "Something went wrong while deleting paperwork",
    ];

    if (e?.response?.data?.errorMessages?.length) {
      errorMessages = e.response.data.errorMessages;
    }
    emitError(...errorMessages);
  } finally {
    showDeleteConfirmationDialog.value = false;
    deleteItem.value = null;
  }
};

const emits = defineEmits<{
  (e: "PageInfoReceived", items: ToolbarItem[]): void;
}>();
emits("PageInfoReceived", [
  {
    callback: reloadUnallocatedPaperwork,
    icon: "mdi-refresh",
    tooltipText: "Refresh overview",
  },
]);
</script>
