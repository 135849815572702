<template>
  <div>
    <v-badge
      bordered
      color="error"
      overlap
      offset-x="10"
      offset-y="13"
      :content="multiParcels.length"
      :value="multiParcels.length"
    >
      <v-text-field
        v-model="singleParcel"
        @blur="filterConfirmed()"
        @click:clear="clearSingle()"
        append-icon="mdi-filter-multiple"
        @click:append="onShowMultiParcelsFilterDialog"
        clearable
        dense
      ></v-text-field>
    </v-badge>

    <v-dialog v-model="showMultiParcelsFilterDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Filter by multiple parcels
        </v-card-title>

        <v-card-text>
          <v-textarea v-model="multiParcelsText"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="light-blue darken-1"
            text
            @click="multiParcelsText = ''"
          >
            Clear
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue darken-1"
            text
            @click="onCancelMultiParcelsFilterDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="light-blue darken-1"
            text
            @click="onConfirmMultiParcelsFilterDialog"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  value: string[] | null | undefined;
}
import { ref, watch, computed, PropType } from "vue";
const emits = defineEmits(["input"]);

const props = defineProps({
  value: {
    type: [Array] as PropType<string[] | null>,
    default: null,
  },
});

const singleParcelText = ref("");
const multiParcelsText = ref("");
const showMultiParcelsFilterDialog = ref(false);
const currentMultiParcels = ref("");

const multiParcels = computed(() => {
  return multiParcelsText.value?.trim()
    ? multiParcelsText.value
        .trim()
        .split("\n")
        .filter((p: string) => p)
        .map((item) => item.trim())
        .filter((item) => item)
    : [];
});

const singleParcel = computed({
  get() {
    return singleParcelText.value;
  },
  set(newVal: string | null) {
    if (newVal === null) {
      singleParcelText.value = "";
      return;
    }
    singleParcelText.value = newVal.trim();
  },
});

watch(
  () => props.value,
  (newVal) => {
    if (newVal && newVal.length > 0) {
      if (newVal.length === 1) {
        singleParcelText.value = newVal[0];
        multiParcelsText.value = "";
      } else {
        singleParcelText.value = "";
        multiParcelsText.value = newVal.join("\n");
      }
    } else {
      singleParcelText.value = "";
      multiParcelsText.value = "";
    }
  },
  { immediate: true, deep: true },
);

const onShowMultiParcelsFilterDialog = () => {
  currentMultiParcels.value = multiParcelsText.value;
  showMultiParcelsFilterDialog.value = true;
};

const onCancelMultiParcelsFilterDialog = () => {
  multiParcelsText.value = currentMultiParcels.value;
  currentMultiParcels.value = "";
  showMultiParcelsFilterDialog.value = false;
};

const onConfirmMultiParcelsFilterDialog = () => {
  showMultiParcelsFilterDialog.value = false;
  filterConfirmed();
};

const clearSingle = () => {
  singleParcelText.value = "";
  filterConfirmed();
};

const filterConfirmed = () => {
  if (multiParcels.value.length > 0) {
    if (multiParcels.value.length === 1) {
      singleParcelText.value = multiParcels.value[0];
      multiParcelsText.value = "";
      emits("input", [singleParcelText.value]);
      return;
    }
    singleParcelText.value = "";
    emits("input", [...multiParcels.value]);
    return;
  }

  if (singleParcelText.value) {
    multiParcelsText.value = "";
    emits("input", [singleParcelText.value]);
  } else {
    emits("input", []);
  }
};
</script>
