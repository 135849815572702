import { render, staticRenderFns } from "./ParcelsTaxformsDialog.vue?vue&type=template&id=21da386f&scoped=true"
import script from "./ParcelsTaxformsDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./ParcelsTaxformsDialog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ParcelsTaxformsDialog.vue?vue&type=style&index=0&id=21da386f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21da386f",
  null
  
)

export default component.exports