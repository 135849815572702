<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Recreate invoices
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="invoice"
                name="Enter invoice number"
                hide-details
                dense
                label="Invoice number"
                outlined
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="!hasInvoice || loading" @click="recreateInvoice">
          Recreate invoices
        </v-btn>
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { FrequentActionsApi } from "@/openapi";
import { emitError, emitSuccess } from "@/event-bus";
interface IProps {
  show: boolean;
}
const api = new FrequentActionsApi(undefined, "");
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);

const loading = ref(false);
const invoice = ref("");
const hasInvoice = computed(() => invoice.value.trim().length > 0);
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    loading.value = false;
    emit("close");
  },
});

const closeDialog = () => {
  loading.value = false;
  invoice.value = "";
  emit("close");
};

const recreateInvoice = () => {
  loading.value = true;
  api
    .recreateInvoice(invoice.value)
    .then((response) => {
      if (response.data) {
        emitSuccess("The invoices have been recreated successfully.");
        closeDialog();
      } else {
        emitError("Something went wrong while recreating invoices.");
      }
    })
    .catch(() => {
      emitError("Something went wrong while recreating invoices.");
      loading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
