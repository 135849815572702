<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2"> Dimensions </v-card-title>
    <v-col>
      <v-data-table
        :headers="shipmentDimensionsHeaders"
        :items="items"
        :items-per-page="10"
        :options.sync="options"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top="{ header }">
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="mr-2"
              @click="addNewItem"
              :disabled="disableNewItemButton"
            >
              <v-icon left>mdi-plus</v-icon>
              Create
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <ValidationObserver
              v-for="item in items"
              :key="item.id"
              v-slot="{ invalid, dirty, validateWithInfo }"
              :ref="'observer-' + item.id"
              slim
            >
              <tr>
                <td>
                  <ValidationProvider name="Length" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.length"
                      placeholder="Length"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="Width" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.width"
                      placeholder="Width"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="Height" rules="required" slim>
                    <EditableTextField
                      class="my-2"
                      v-model="item.height"
                      placeholder="Height"
                    ></EditableTextField>
                  </ValidationProvider>
                </td>
                <td>{{ item.volumeWeight }}</td>
                <td>
                  <div class="d-flex justify-end">
                    <v-btn
                      class="mr-2"
                      color="success"
                      :disabled="invalid || !dirty || item.isDeleting"
                      :loading="item.isSaving"
                      small
                      @click="onSave(item, validateWithInfo)"
                      ><v-icon left>mdi-floppy</v-icon>Save</v-btn
                    >
                    <v-btn
                      color="error"
                      small
                      :disabled="item.isDeleting"
                      :loading="item.isDeleting"
                      @click="onDelete(item)"
                      ><v-icon left> mdi-delete </v-icon>Delete</v-btn
                    >
                  </div>
                </td>
              </tr>
            </ValidationObserver>
          </tbody>
        </template>
      </v-data-table>
    </v-col>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="closeDialog()" color="primary" text> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { useCrudPage } from "@/composables/crudPage";
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { DataOptions } from "vuetify";
import EditableTextField from "@/components/EditableTextField.vue";
import {
  ShipmentDetailViewModel,
  ShipmentDetailDimensionModel,
  ShipmentDetailApi,
} from "@/openapi";

interface IProps {
  detail: ShipmentDetailViewModel;
}

const props = defineProps<IProps>();
const emit = defineEmits(["update:open-dialog", "update:detail"]);
const api = new ShipmentDetailApi(undefined, "");

interface EnrichedShipmentGeneralModel extends ShipmentDetailDimensionModel {
  isSaving: boolean;
  isDeleting: boolean;
}

const defaultDimension: EnrichedShipmentGeneralModel = {
  id: 0,
  unit: 1,
  length: 0,
  width: 0,
  height: 0,
  volumeWeight: 0,
  isSaving: false,
  isDeleting: false,
  cha_Id: 0,
};

const {
  items,
  disableNewItemButton,
  isLoading,
  addNewItem,
  deleteItem,
  isNewItem,
} = useCrudPage<EnrichedShipmentGeneralModel>(defaultDimension, "id", 0);

const isSaving = ref(false);
const isDeleting = ref(false);

const shipmentDimensionsHeaders = ref([
  { text: "Length", value: "length" },
  { text: "Width", value: "width" },
  { text: "Height", value: "height" },
  { text: "Volumeweight", value: "volumeWeight" },
  { text: " ", value: "actions", sortable: false, width: "250px" },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const loadItems = async () => {
  isLoading.value = true;
  try {
    items.value =
      props.detail.dimensions?.map((item) => ({
        ...item,
        isSaving: false,
        isDeleting: false,
      })) ?? [];
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while retrieving the keywords",
    );
  }
  isLoading.value = false;
};

const closeDialog = () => {
  emit("update:open-dialog", false);
};

const onSave = async (
  item: EnrichedShipmentGeneralModel,
  validate: () => Promise<boolean>,
) => {
  const isValid = await validate();
  if (!isValid) {
    return;
  }
  isSaving.value = true;
  item.isSaving = true;

  await saveCustomsKeyword(item);
  disableNewItemButton.value = false;
};

const saveCustomsKeyword = async (item: EnrichedShipmentGeneralModel) => {
  isSaving.value = true;

  item.isSaving = true;
  if (item.cha_Id < 1) {
    item.cha_Id = props.detail.id;
  }
  let volumeWeight = (item.length * item.width * item.height) / 6000;
  if (volumeWeight < 0.02) {
    volumeWeight = 0.02;
  } else {
    volumeWeight = Number(volumeWeight.toPrecision(2));
  }
  item.volumeWeight = volumeWeight;
  const response = await api.saveShipmentDetailDimension(item);
  item.id = response.data.id;
  props.detail.dimensions = items.value;
  emit("update:detail", props.detail);

  emitSuccess("Successfully saved the keyword");

  isSaving.value = false;
  item.isSaving = false;
};

const onDelete = async (item: EnrichedShipmentGeneralModel) => {
  isDeleting.value = true;
  item.isDeleting = true;

  await deleteCustomsKeyword(item);

  item.isDeleting = false;
  isDeleting.value = false;
};

const deleteCustomsKeyword = async (item: EnrichedShipmentGeneralModel) => {
  try {
    const response = await api.deleteShipmentDetailDimension(item.id);
    if (response.data) {
      props.detail.dimensions = items.value;
      emit("update:detail", props.detail);
    }
  } catch (error) {
    emitErrorWithFallback(
      error,
      "Something went wrong while deleting the keyword",
    );
  }
};

onBeforeMount(async () => {
  await loadItems();
});
</script>
