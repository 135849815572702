import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface SetHawbNumberForm {
    hawbNumber: string;
}

@Component({})
export default class SetHawbNumberDialog extends Vue {
    @Prop({default: false})
    value!: Boolean;

    @Prop({default: false})
    isLoading!: Boolean;

    @Prop({default: 'Set HAWB'})
    title!: String

    @Prop({default: 'Confirm'})
    confirmButtonText!: String

    @Prop({default: 'Close'})
    closeButtonText!: String

    rules = {
        required: (value: string) =>
            (value && !!value.toString()) || 'Required'
    };

    valid = true;
    form: SetHawbNumberForm = { hawbNumber: ''}


    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if (!newValue) {
            (this.$refs.form as Vue & {
                resetValidation: () => boolean;
            }).resetValidation();

            this.form.hawbNumber = '';
        }
    }

    onEnter() {
        if(!this.valid) {
            return;
        }
        this.emitValues();
    }

    emitValues() {
        this.$emit('confirm', this.form);
    }
}