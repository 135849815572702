<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Request shipment data for HAWB numbers
      </v-card-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="yellow darken-2"
      ></v-progress-linear
      ><v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-textarea
                v-model="ids"
                name="Enter invoice numbers"
                hide-details
                dense
                label="Invoice numbers"
                outlined
                :loading="loading"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="form.inboundWarehouse"
                label="Inbound warehouse"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.warehouseLocation"
                label="Warehouse location"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.customsCleared"
                label="Customs cleared"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.preCustomsCleared"
                label="Pre customs cleared"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.invoiceNumber"
                label="Invoice number"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.inspection"
                label="Inspection"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.incoterm"
                label="Incoterm"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.pieces"
                label="Pieces"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.weight"
                label="Weight"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.generalCustomer"
                label="General customer"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.shipperName"
                label="Shipper name"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="form.consigneeName"
                label="Consignee name"
                class="mt-0 pt-0"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row> </v-container
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isFormValid || loading"
          text
          @click="downloadExcelFile()"
        >
          Download Excel file</v-btn
        >
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import {
  FrequentActionsApi,
  ShipmentDataForHAWBNumbersFromModel,
} from "@/openapi";
import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
import { emitError } from "@/event-bus";
interface IProps {
  show: boolean;
}
const api = new FrequentActionsApi(undefined, "");
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);

const loading = ref(false);
const defaultForm = {
  hawbNumbers: [],
  inboundWarehouse: true,
  warehouseLocation: true,
  customsCleared: true,
  preCustomsCleared: true,
  invoiceNumber: true,
  inspection: true,
  incoterm: true,
  pieces: true,
  weight: true,
  generalCustomer: true,
  shipperName: true,
  consigneeName: true,
};
const form = ref<ShipmentDataForHAWBNumbersFromModel>(
  structuredClone(defaultForm),
);
const ids = ref("");
const idsist = computed(() =>
  ids.value.split("\n").filter((item) => item.trim() !== ""),
);
const hasIds = computed(() => idsist.value.length > 0);
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const closeDialog = () => {
  loading.value = false;
  form.value = structuredClone(defaultForm);
  ids.value = "";
  emit("close");
};

const isFormValid = computed(() => {
  return hasIds.value;
});

const downloadExcelFile = () => {
  loading.value = true;
  form.value.hawbNumbers = idsist.value;
  api
    .shipmentDataForHAWBNumbers(form.value, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = getFileNameFromContentDispositionHeader(response);
      downloadFile(response.data, fileName!);
      closeDialog();
    })
    .catch(() => {
      emitError("Something went wrong while requesting shipments.");
      loading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
