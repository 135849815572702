import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as CustomsExportController from "../api/CustomsExportController";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue.html";
import { CustomsShipmentDetailViewModel } from "@/api/viewmodels/CustomsShipmentDetailViewModel";
import AddRouteDialog from "./dialogs/AddRouteDialog.vue.html";
import AddMrnDialog from "./dialogs/AddMrnDialog.vue.html";
import ConfirmShipmentReasonDialog from "./dialogs/ConfirmShipmentReasonDialog.vue.html";
import CustomsFlowStepsMixin from "@/mixins/CustomsFlowStepsMixin";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue.html";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CreateDakosyExportRequestArticle,
  CustomsExportHighApi,
  CustomsHighShipmentItemViewModel,
} from "@/openapi";
import CountriesMixin from "@/mixins/CountriesMixin";
import ShipmentPaperwork from "./ShipmentPaperwork.vue.html";
import ToolbarMixin from "@/mixins/ToolbarMixin";
import FilterParcels from "./FilterParcels.vue";
import DakosyExportAusfuhrInfoDialog from "@/components/dialogs/DakosyExportAusfuhrInfoDialog.vue";

import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue.html";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue.html";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue.html";
import { DakosyExportData } from "@/types/types";
import { emitErrorWithFallback } from "@/event-bus";

const customsExportHighApi = new CustomsExportHighApi(undefined, "");

interface DakosyExportButtonProperties {
  dakosyButtonIcon?: string;
  dakosyButtonColor?: string;
  dakosyButtonText?: string;
  dakosyButtonLoading?: boolean;
}

@Component({
  components: {
    CustomsExportShipmentDetailViewNew,
    AddRouteDialog,
    AddMrnDialog,
    ShipmentStatusFlow,
    ConfirmShipmentReasonDialog,
    ShipmentPaperwork,
    FilterParcels,
    ReturnShipmentButton,
    CustomsInspectionButton,
    HoldShipmentButton,
    DakosyExportAusfuhrInfoDialog,
  },
})
export default class CustomsExportShipmentsHigh extends mixins(
  CustomsFlowStepsMixin,
  CountriesMixin,
  ToolbarMixin,
) {
  @Prop({ default: "" })
  color!: string;

  isLoading = false;
  options: any = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };
  filter: any = {
    step: null,
    shipmentStatusDescription: "",
    parcels: [],
    exportMrn: "",
    shipperCountry: null,
    consigneeCountry: null,
  };
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
  };
  totalItems: Number = 0;
  expanded = [];

  loadingSimulateAutomatedStep = false;

  checkShipmentStep = 2;
  automatedSteps = [6, 9, 10, 11, 13]; // for testing

  statusFilter: number[] = [];
  shipmentStatusFilter = [
    "Hold",
    "Return",
    "Customs inspection",
    "Loading confirmed",
    "Waiting for customs release",
    "Customs EXIT released",
  ];

  items: CustomsHighShipmentItemViewModel[] = [];
  currentHandlingItem:
    | (CustomsHighShipmentItemViewModel & DakosyExportButtonProperties)
    | null = null;
  currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
  selectedHandlingItems: CustomsHighShipmentItemViewModel[] = [];
  noItemsSelected = false;

  showDialogAddMrn = false;
  dialogAddMrnLoading = false;

  releaseAndMoveLoading = false;
  showDialogAddRoute = false;
  dialogAddRouteLoading = false;

  showDialogAusfuhrInfo = false;

  dakosyExportStatus = {
    createExport: 1,
    createdExport: 2,
    sendToDakosy: 3,
    error: 4,
  };

  headers = [
    {
      text: "Dakosy",
      value: "dakosy",
    },
    {
      text: "Step",
      value: "exportFlowStatus",
      align: "center",
    },
    {
      text: "Shipment status",
      value: "shipmentStatusDescription",
    },
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
      width: "15em",
    },
    {
      text: "MRN ",
      value: "exportMrn",
      width: "18em",
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
      align: "end",
    },
    {
      text: "Gewicht ",
      value: "grossWeight",
      sortable: false,
      align: "end",
    },
    { text: "EORI", value: "eoriNumber", sortable: false },
    {
      text: "Versender",
      value: "shipper",
      sortable: false,
      width: "15em",
    },
    {
      text: "Empfänger",
      value: "consignee",
      sortable: false,
      width: "15em",
    },
    {
      text: "Warenwert",
      value: "value",
      sortable: false,
      align: "end",
    },
    { text: "Währung", value: "valueCurrency", sortable: false },
    {
      text: "Warenbeschreibung / tarifnummer",
      value: "articles",
      sortable: false,
      width: "25em",
    },
  ];

  get hasSelectedHandlingItems() {
    return !!this.selectedHandlingItems?.length;
  }

  get dataTableItems() {
    return this.items.map((c) => ({
      ...c,
      isSelectable: !!c.exportMrn || !!c.exportMrns?.length,
      ...this.getDakosyButtonProperties(c),
    }));
  }

  getDakosyButtonProperties(shipment: CustomsHighShipmentItemViewModel) {
    let dakosyButtonColor: string = "";
    let dakosyButtonIcon: string = "";
    let dakosyButtonText: string = "";

    if (shipment.dakosyExportStatus === this.dakosyExportStatus.createExport) {
      dakosyButtonColor = "primary";
      dakosyButtonIcon = "mdi-export-variant";
      dakosyButtonText = "Create Dakosy export";
    } else if (
      shipment.dakosyExportStatus === this.dakosyExportStatus.createdExport
    ) {
      dakosyButtonColor = "blue";
      dakosyButtonIcon = "mdi-sort-clock-descending-outline";
      dakosyButtonText = "Created export";
    } else if (
      shipment.dakosyExportStatus === this.dakosyExportStatus.sendToDakosy
    ) {
      dakosyButtonColor = "success";
      dakosyButtonIcon = "mdi-check";
      dakosyButtonText = "Send to Dakosy";
    } else if (shipment.dakosyExportStatus === this.dakosyExportStatus.error) {
      dakosyButtonColor = "error";
      dakosyButtonIcon = "mdi-alert-circle-outline";
      dakosyButtonText = "Error";
    }

    return {
      dakosyButtonColor: dakosyButtonColor,
      dakosyButtonIcon: dakosyButtonIcon,
      dakosyButtonText: dakosyButtonText,
      dakosyButtonLoading: false,
    } as DakosyExportButtonProperties;
  }

  created() {
    this.statusFilter = getStatuses();

    this.addRefreshToolbarOption(this.reloadShipments);
  }

  async loadShipments(page: number) {
    this.isLoading = true;
    try {
      const response = await customsExportHighApi.getHighShipments({
        page,
        itemsPerPage: this.options.itemsPerPage,
        step: this.filter.step ?? undefined,
        shipmentStatusDescription:
          this.filter.shipmentStatusDescription ?? undefined,
        parcels: this.filter.parcels,
        exportMrn: this.filter.exportMrn ?? undefined,
        shipperCountry: this.filter.shipperCountry ?? undefined,
        consigneeCountry: this.filter.consigneeCountry ?? undefined,
      });

      this.items = response.data.shipments ?? [];
      this.totalItems = response.data.totalAmount ?? 0;
    } catch {
      this.displayError("Something went wrong while retrieving the shipments");
    }
    this.isLoading = false;
  }

  async reloadShipments() {
    this.options.page == 1
      ? await this.loadShipments(this.options.page)
      : (this.options.page = 1);
  }

  timeoutDelay = 0;
  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.loadShipments(this.options.page);
    }, 250);
  }

  @Watch("expanded")
  onExpandedChanged(
    newVal: (CustomsHighShipmentItemViewModel & DakosyExportButtonProperties)[],
    oldVal: (CustomsHighShipmentItemViewModel & DakosyExportButtonProperties)[],
  ) {
    if (newVal.length === 0) {
      this.currentHandlingItem = null;
      this.currentShipmentDetail = null;
    } else {
      this.currentHandlingItem = newVal[0];

      this.isLoading = true;
      this.currentShipmentDetail = null;
      CustomsExportController.getShipmentDetail(this.currentHandlingItem.id!)
        .then((response) => (this.currentShipmentDetail = response.data))
        .catch((error) =>
          this.displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (this.isLoading = false));
    }
  }

  openDialogShipmentAddMrn(item: CustomsHighShipmentItemViewModel) {
    this.stopLoadingButtons();
    this.currentHandlingItem = item;
    this.showDialogAddMrn = true;
  }

  onFilterSelected() {
    this.reloadShipments();
  }

  stopLoadingButtons() {
    this.loadingSimulateAutomatedStep = false;
  }

  closeAllDialogs() {
    this.showDialogAddMrn = false;
    this.showDialogAddRoute = false;
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  async onReleaseAndMoveSelected() {
    if (this.selectedHandlingItems.every((item) => item.hasRoute)) {
      this.releaseAndMoveLoading = true;
      this.moveShipmentsToTransit()
        .then(() => {
          this.loadShipments(this.options.page);
        })
        .finally(() => (this.releaseAndMoveLoading = false));
    } else {
      this.showDialogAddRoute = true;
    }
  }

  async onRouteSelected(value: any) {
    this.dialogAddRouteLoading = true;

    this.moveShipmentsToTransit(value.routeId)
      .then(() => {
        this.dialogAddRouteLoading = false;
        this.showDialogAddRoute = false;
        this.loadShipments(this.options.page);
      })
      .finally(() => {
        this.dialogAddRouteLoading = false;
        this.showDialogAddRoute = false;
      });
  }

  async onMrnEntered({ mrn }: { mrn: string }) {
    this.dialogAddMrnLoading = true;
    try {
      await CustomsExportController.setMrn({
        mrn: mrn,
        shipmentId: this.currentHandlingItem!.id!,
      });

      var currentItem = this.items.find(
        (c) => c.id === this.currentHandlingItem!.id,
      );
      currentItem!.exportMrn = mrn;
    } catch {
      this.displayError(
        "Something went wrong while setting the MRN of the shipment",
      );
    }

    this.currentHandlingItem = null;
    this.dialogAddMrnLoading = false;
    this.showDialogAddMrn = false;
  }

  async exportToDakosy(
    item: CustomsHighShipmentItemViewModel & DakosyExportButtonProperties,
  ) {
    if (item.dakosyExportStatus !== this.dakosyExportStatus.createExport) {
      return;
    }

    this.showDialogAusfuhrInfo = true;
    this.currentHandlingItem = item;
  }

  async exportToDakosyConfirm(data: DakosyExportData) {
    if (!this.currentHandlingItem) {
      return;
    }

    this.currentHandlingItem.dakosyButtonLoading = true;
    try {
      await customsExportHighApi.createDakosyExport({
        endDate: data.endDate ?? "",
        presentationDate: data.presentationDate ?? "",
        shipmentId: this.currentHandlingItem.id,
        useShipperAddress: data.useShipperAddress,
        abdEmail: data.aBDEmail,
        articles: data.articles.map(
          (c) =>
            ({
              id: c.id,
              wtn: c.wtn,
              goodsDescription: c.goodsDescription,
              ownWeight: c.ownWeight,
              grossWeight: c.grossWeight,
              rchsCode: c.rchsCode,
              invoiceAmount: c.invoiceAmount,
              invoiceAmountCurrency: c.invoiceCurrency,
              freightCostAmount: c.freightCostsAmount,
              freightCostAmountCurrency: c.freightCostsCurrency,
              statAmount: c.statAmount,
              statAmountUnit: c.statUnit,
            }) as CreateDakosyExportRequestArticle,
        ),
        ausfuhrInfoId: data.addressId,
        ausfuhrzollstelle: data.ausfuhrzollstelle,
        avmEmail: data.aVMEmail,
        remarkSpecialCircumstancesCharacteristics:
          data.remarkSpecialCircumstancesCharacteristics,
        specialCircumstancesCharacteristics:
          data.specialCircumstancesCharacteristics,
        vorgeseheneAusgangszollstelle: data.vorgeseheneAusgangszollstelle,
        zusatz: data.zusatz,
      });
      const currentId = this.currentHandlingItem.id;
      const foundItem = this.items.find((c) => c.id === currentId);
      if (foundItem) {
        foundItem.dakosyExportStatus = this.dakosyExportStatus.createdExport;
      }
    } catch (e: unknown) {
      emitErrorWithFallback(e, "Something went wrong during the Dakosy export");
    } finally {
      this.showDialogAusfuhrInfo = false;
      this.currentHandlingItem.dakosyButtonLoading = false;
      this.currentHandlingItem = null;
    }
  }

  async moveShipmentsToTransit(routeId?: number) {
    try {
      const response = await customsExportHighApi.moveShipmentsToTransit({
        routeId: routeId,
        shipmentIds: this.selectedHandlingItems.map((item) => item.id!),
      });

      this.selectedHandlingItems = [];
      return response;
    } catch (e) {
      this.displayError(
        'Something went wrong while executing the "Release and move shipments to transit" action.',
      );
      throw e;
    }
  }

  onShipmentStatusChanged({ success }: any) {
    if (success) {
      this.selectedHandlingItems = [];
      this.loadShipments(this.options.page);
    }
  }
}
