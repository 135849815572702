import { ShipmentDetailInvoice } from "@/openapi";
export const sendByDDL: any[] = [
  { value: 1, text: "Email" },
  { value: 2, text: "Post" },
  { value: 3, text: "Fax" },
];

export const getInvoiceNumbers = (
  invoices: ShipmentDetailInvoice[] | null | undefined,
): string => {
  return invoices?.map((i) => i.invoiceNumber).join(", ") ?? "";
};
