<template>
  <v-card class="pa-3 weight-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Gewicht / Preise</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.weightPrice.billingPrice"
          label="Rechnungspreis"
          outlined
          hide-details
          dense
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.weightPrice.billingPriceCurrency"
          :items="dataStore.currencies"
          item-text="name"
          item-value="name"
          hide-details
          dense
          label="EUR"
          outlined
          readonly
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.weightPrice.freightCosts"
          label="Frachtkosten"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.weightPrice.freightCostCurrency"
          :items="dataStore.currencies"
          item-text="name"
          item-value="name"
          hide-details
          dense
          label="EUR"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.weightPrice.customsCosts"
          label="Zollwert (EUR)"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.weightPrice.customsClearanceOffices"
          :items="dataStore.customsOffices"
          item-text="shortNumber"
          item-value="shortNumber"
          hide-details
          dense
          label="Abfertigungszollstelle"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" class="checkbox-no-mt pb-0">
        <v-checkbox
          v-model="detail.weightPrice.splitFreightCostOverAllPositions"
          hide-details
          label="Frachtkosten auf alle Positionen aufteilen"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}
defineProps<IProps>();
const dataStore = useDataStore();
</script>
