<template>
  <v-card class="pa-3 references-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>References</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.mawbNumber"
          label="MAWB"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.hawbNumber"
          label="HAWB"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="detail.references.warehouseLocation"
          label="Wareh. Location"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="detail.references.origin"
          :items="dataStore.airports"
          item-text="value"
          item-value="key"
          hide-details
          dense
          label="Origin"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.placeholder.connectedSchemeId"
          :items="dataStore.customsSchemes"
          item-text="value"
          item-value="key"
          hide-details
          dense
          label="Connected scheme"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.iossNumber"
          label="IOSS number"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.atbNummerPosition"
          label="Road ATB number"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.atb"
          label="Road ATB position"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="detail.references.airportAtbNumber"
          label="Airport ATB number"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.references.airportAtbNumberPosition"
          label="Airport ATB position"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="8">
        <v-select
          v-model="detail.orderDetails.customerId"
          :items="dataStore.generalCustomers"
          item-text="shortName"
          item-value="id"
          hide-details
          dense
          label="customer"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-btn color="primary">Pre-alert</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();
const dataStore = useDataStore();
</script>
