import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Customs from "@/views/Customs.vue";
import CustomsExport from "../views/CustomsExport.vue.html";
import Login from "../views/Login.vue.html";
import HAWB from "../views/Hawb.vue.html";
import Mistakes from "../views/Mistakes.vue.html";
import FileDownload from "../views/FileDownload.vue.html";
import ArticlesPage from "../views/ArticlesPage.vue";
import DakosyExport from "../views/DakosyExport.vue.html";
import InboundFreightcosts from "../views/CustomsOfficeCustomers.vue.html";
import WarehouseLocations from "../views/WarehouseLocations.vue.html";
import CustomsOfficeExportFlow from "../views/CustomsOfficeExportFlow.vue.html";
import CustomsExportInformation from "../views/CustomsExportInformation.vue.html";
import Employees from "../views/Employees.vue";
import store from "../store";
import LoggedIn from "../views/LoggedIn.vue.html";
import ReturnShipments from "../views/ReturnShipments.vue.html";
import FlightHandling from "../views/FlightHandling.vue";
import PaymentReminderSettings from "../views/PaymentReminderSettings.vue.html";
import PaymentReminderEmails from "../views/PaymentReminderEmails.vue.html";
import CustomsCountries from "../views/CustomsCountries.vue.html";
import CustomsDestinationDistricts from "../views/CustomsDestinationDistricts.vue.html";
import CustomsCountriesEU from "../views/CustomsCountriesEU.vue.html";
import CustomsTypesOfBusiness from "../views/CustomsTypesOfBusiness.vue.html";
import CustomsTransportTypes from "../views/CustomsTransportTypes.vue.html";
import CustomsCodes from "../views/CustomsCodes.vue.html";
import CustomsDeliveryConditions from "../views/CustomsDeliveryConditions.vue.html";
import CustomsKeywords from "../views/CustomsKeywords.vue";
import CustomsKostenstellen from "../views/CustomsKostenstellen.vue.html";
import CustomsInvoiceTaxKeys from "../views/CustomsInvoiceTaxKeys.vue.html";
import CustomsInvoiceCategories from "../views/CustomsInvoiceCategories.vue.html";
import BookingAccounts from "../views/BookingAccounts.vue.html";
import AddClaim from "../views/AddClaim.vue.html";
import Claims from "../views/Claims.vue.html";
import Home from "../views/Home.vue.html";
//import CustomsForbiddenTarif from '@/views/CustomsForbiddenTarif.vue.html';
import CustomsExportCustomers from "@/views/CustomsExportCustomers.vue.html";
import CustomsMilestoneHandling from "@/views/CustomsMilestoneHandling.vue.html";
import CustomsStatusCustomers from "@/views/CustomsStatusCustomers.vue.html";
import CustomsArticleMaintenance from "@/views/CustomsArticleMaintenance.vue.html";
import Schemes from "../views/Schemes.vue";
import CustomerManagement from "../views/CustomerManagement.vue";
import EmailTemplate from "../views/EmailTemplate.vue";
//import Ratecards from '../views/Ratecards.vue.html';
//import BillingServices from '../views/BillingServices.vue.html';
import CustomsTransitDeclarationManagement from "@/views/CustomsTransitDeclarationManagement.vue";
import PreCheckShipments from "@/views/PreCheckShipments.vue";
import CsvInvoiceExportTemplates from "../views/CsvInvoiceExportTemplates.vue";
import DutiesAndTaxes from "@/views/DutiesAndTaxes.vue";
import MrnDiscrepancies from "@/views/MrnDiscrepancies.vue";
import DistributedJobs from "@/views/admin/DistributedJobs.vue";
import DebtorNumbers from "@/views/DebtorNumbers.vue";
import HawbDefinitions from "@/components/HawbDefinitions.vue";
import MasterOverview from "@/views/MasterOverview.vue";
import EmployeeCustoms from "@/views/employees/EmployeeCustoms.vue";
import ImportClearances from "@/views/dashboards/ImportClearances.vue";
import ProblemShipments from "@/views/ProblemShipments.vue";
import PassThrough from "@/views/PassThrough.vue";
import CustomsAusfuhrInfoList from "@/views/ausfuhr/CustomsAusfuhrInfoList.vue";
import CustomsAusfuhrInfoDetail from "@/views/ausfuhr/CustomsAusfuhrInfoDetail.vue";
import ArticleCategories from "@/views/ArticleCategories.vue";
import DocumentShipments from "@/components/DocumentShipments.vue";
import PaymentTerms from "@/components/PaymentTerms.vue";
import InvoiceFooterTexts from "@/components/InvoiceFooterTexts.vue";
import Fileimports from "@/views/FileImports.vue";
import FremdGestellung from "@/components/file-import/FremdGestellung.vue";
import FileImportList from "@/components/file-import/FileImportList.vue";
import ContainerManagement from "@/views/ContainerManagement.vue";
import Containers from "@/components/containers/Containers.vue";
import ContainerManifests from "@/components/containers/ContainerManifests.vue";
import ShipmentDetails from "@/views/shipmentDetail/ShipmentDetails.vue";
import ShipmentDetailsPage from "@/views/shipmentDetail/ShipmentDetailsPage.vue";
import EmployeeOverview from "@/components/employee/EmployeeOverview.vue";
import EmployeeUserRights from "@/components/employee/EmployeeUserRights.vue";
import EmployeeDetail from "@/components/employee/EmployeeDetail.vue";
import EmployeeDetailData from "@/components/employee/EmployeeDetailData.vue";
import EmployeeDetailRights from "@/components/employee/EmployeeDetailRights.vue";
import EmployeeBulkAssignment from "@/components/employee/EmployeeBulkAssignment.vue";
import FrequentActions from "@/pages/admin/frequent-actions/FrequentActions.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: LoggedIn,
    meta: { loggedIn: true },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/customsimport",
        name: "Customs",
        component: Customs,
      },
      {
        path: "/customsexport",
        name: "Customs Export",
        component: CustomsExport,
      },
      {
        path: "/hawb",
        name: "HAWB",
        component: HAWB,
      },
      {
        path: "/mistakes",
        name: "Dakosy mistakes",
        component: Mistakes,
      },
      {
        path: "/filedownload",
        name: "Download files",
        component: FileDownload,
      },
      {
        path: "/dakosyexport",
        name: "Dakosy Export",
        component: DakosyExport,
      },
      {
        path: "/inboundfreightcosts",
        name: "InboundFreightcosts",
        component: InboundFreightcosts,
      },
      {
        path: "/customsmilestonehandling",
        name: "CustomsMilestoneHandling",
        component: CustomsMilestoneHandling,
      },
      {
        path: "/customsstatuscustomers",
        name: "CustomsStatusCustomers",
        component: CustomsStatusCustomers,
      },
      {
        path: "/warehouselocations",
        name: "Warehouse Locations",
        component: WarehouseLocations,
      },
      {
        path: "/customsexportinformation",
        name: "Customs export information",
        component: CustomsExportInformation,
      },
      {
        path: "/employees",
        name: "Employees",
        redirect: { name: "EmployeeOverview" },
        component: Employees,
        children: [
          {
            path: "",
            name: "EmployeeOverview",
            component: EmployeeOverview,
          },
          {
            path: "assigned-user-rights",
            name: "EmployeeUserRights",
            component: EmployeeUserRights,
          },
        ],
      },
      {
        path: "/assign-bulk-user-rights",
        name: "EmployeeBulkAssignment",
        component: EmployeeBulkAssignment,
        props: (route) => {
          let employeeIds: number[] = [];
          if (route.params.employeeIds) {
            employeeIds = JSON.parse(route.params.employeeIds);
          }
          return {
            employeeIds: employeeIds,
          };
        },
      },
      {
        path: "/employees/detail/:employeeId?",
        name: "EmployeeDetail",
        redirect: { name: "EmployeeDetailInfo" },
        component: EmployeeDetail,
        props: (route) => {
          let id: number | undefined;
          if (route.params.employeeId) {
            const parsedId = parseInt(route.params.employeeId);
            id = parsedId || undefined;
          }
          return {
            employeeId: id,
          };
        },
        children: [
          {
            path: "",
            name: "EmployeeDetailInfo",
            component: EmployeeDetailData,
            props: (route) => {
              let id: number | undefined;
              if (route.params.employeeId) {
                const parsedId = parseInt(route.params.employeeId);
                id = parsedId || undefined;
              }
              return {
                employeeId: id,
              };
            },
          },
          {
            path: "user-rights",
            name: "EmployeeDetailRights",
            component: EmployeeDetailRights,
            props: (route) => {
              let id: number | undefined;
              if (route.params.employeeId) {
                const parsedId = parseInt(route.params.employeeId);
                id = parsedId || undefined;
              }
              return {
                employeeId: id,
              };
            },
          },
        ],
      },
      {
        path: "/employeeuserrights",
        name: "Employee User Rights",
        component: EmployeeUserRights,
      },
      {
        path: "/customscountries",
        name: "Countries",
        component: CustomsCountries,
      },
      {
        path: "/customstypesofbusiness",
        name: "Types Of Business",
        component: CustomsTypesOfBusiness,
      },
      {
        path: "/customstransporttypes",
        name: "Transport Types",
        component: CustomsTransportTypes,
      },
      {
        path: "/customscodes",
        name: "Customs Codes",
        component: CustomsCodes,
      },
      {
        path: "/customsinvoicetaxkeys",
        name: "Customs Invoice Tax Keys",
        component: CustomsInvoiceTaxKeys,
      },
      {
        path: "/customskostenstellen",
        name: "Customs Kostenstellen",
        component: CustomsKostenstellen,
      },
      {
        path: "/customsinvoicecategories",
        name: "Customs Invoice Categories",
        component: CustomsInvoiceCategories,
      },
      {
        path: "/customsdeliveryconditions",
        name: "Delivery Conditions",
        component: CustomsDeliveryConditions,
      },
      {
        path: "/customsdestinationdistricts",
        name: "CustomsDestinationDistricts",
        component: CustomsDestinationDistricts,
      },
      {
        path: "/customscountrieseu",
        name: "Countries EU",
        component: CustomsCountriesEU,
      },
      {
        path: "/returnshipments",
        name: "Return shipments",
        component: ReturnShipments,
      },
      {
        path: "/flighthandling",
        name: "Flight Handling",
        component: FlightHandling,
      },
      {
        path: "/paymentremindersettings",
        name: "Payment Reminder Settings",
        component: PaymentReminderSettings,
      },
      {
        path: "/paymentreminderemails",
        name: "Payment Reminder Emails",
        component: PaymentReminderEmails,
      },
      {
        path: "/fileimport",
        component: Fileimports,
        children: [
          {
            path: "",
            name: "File import",
            component: FileImportList,
          },
          {
            path: "fremdgestellung",
            name: "FremdGestellung",
            component: FremdGestellung,
          },
        ],
      },
      {
        path: "/bookingaccounts",
        name: "Booking accounts",
        component: BookingAccounts,
      },
      {
        path: "/addclaim",
        name: "Add Claim",
        component: AddClaim,
      },
      {
        path: "/claims",
        name: "Claims",
        component: Claims,
      },
      {
        path: "/emailtemplate",
        name: "EmailTemplate",
        component: EmailTemplate,
      },
      {
        path: "/schemes",
        name: "Schemes",
        component: Schemes,
      },
      {
        path: "/customermanagement",
        name: "CustomerManagement",
        component: CustomerManagement,
      },
      //{
      //    path: '/ratecards',
      //    name: 'Ratecards',
      //    component: Ratecards,
      //},
      //{
      //    path: '/billingservices',
      //    name: 'BillingServices',
      //    component: BillingServices,
      //},
      {
        path: "/precheckshipments",
        name: "PreCheckShipments",
        component: PreCheckShipments,
      },
      {
        path: "/csvinvoiceexporttemplates",
        name: "CsvInvoiceExportTemplates",
        component: CsvInvoiceExportTemplates,
      },
      {
        path: "/bundeskasse",
        name: "DutiesAndTaxes",
        component: DutiesAndTaxes,
      },
      {
        path: "/mrn-discrepancies",
        name: "MrnDiscrepancies",
        component: MrnDiscrepancies,
      },
      {
        path: "/admin/distributed-import-jobs",
        name: "DistributedImportJobs",
        component: DistributedJobs,
      },
      {
        name: "Frequent actions",
        path: "/admin/frequent-actions",
        component: FrequentActions,
      },
      {
        path: "/debtor-numbers",
        name: "DebtorNumbers",
        component: DebtorNumbers,
      },
      {
        path: "/hawb-definitions",
        name: "HawbDefinitions",
        component: HawbDefinitions,
      },
      {
        path: "/operational-add-ons/master-overview",
        name: "MasterOverview",
        component: MasterOverview,
      },
      {
        path: "/employees/customs",
        name: "EmployeeCustoms",
        component: EmployeeCustoms,
      },
      {
        path: "/dashboards/import-clearances",
        name: "ImportClearances",
        component: ImportClearances,
      },
      {
        path: "operational-add-ons/problem-shipments",
        name: "ProblemShipments",
        component: ProblemShipments,
      },
      {
        path: "maintenance",
        component: PassThrough,
        children: [
          {
            path: "operational",
            component: PassThrough,
            children: [
              {
                path: "shipment-consolidation-customers",
                name: "MaintenanceOperationalDocumentShipments",
                component: DocumentShipments,
              },
              {
                path: "transit-declaration-management",
                name: "MaintenanceOperationalTransitDeclarationManagement",
                component: CustomsTransitDeclarationManagement,
              },
              {
                path: "customs-keywords",
                name: "MaintenanceOperationalCustomsKeywords",
                component: CustomsKeywords,
              },
              {
                path: "customs-office-export-flow",
                name: "MaintenanceOperationalCustomsOfficeExportFlow",
                component: CustomsOfficeExportFlow,
              },
              {
                path: "customs-export-customers",
                name: "MaintenanceOperationalCustomsExportCustomers",
                component: CustomsExportCustomers,
              },
              {
                path: "customs-article-maintenance",
                name: "MaintenanceOperationalCustomsArticleMaintenance",
                component: CustomsArticleMaintenance,
              },
              {
                path: "articles",
                name: "MaintenanceOperationalArticles",
                component: ArticlesPage,
              },
              {
                path: "article-categories",
                name: "MaintenanceOperationalArticleCategories",
                component: ArticleCategories,
              },
              {
                path: "warenorte-export",
                name: "MaintenanceOperationalCustomsAusfuhrInfoList",
                component: CustomsAusfuhrInfoList,
              },
              {
                path: "warenorte-export/detail/:id?",
                name: "MaintenanceOperationalCustomsAusfuhrInfoDetail",
                component: CustomsAusfuhrInfoDetail,
                props: (route) => {
                  let id: number | undefined;
                  if (route.params.id) {
                    const parsedId = parseInt(route.params.id);

                    id = parsedId || undefined;
                  }
                  return {
                    id: id,
                  };
                },
              },
            ],
          },
        ],
      },
      {
        path: "/paymentterms",
        name: "PaymentTerms",
        component: PaymentTerms,
      },
      {
        path: "/invoicefootertexts",
        name: "InvoiceFooterTexts",
        component: InvoiceFooterTexts,
      },
      {
        path: "/container-management",
        component: ContainerManagement,
        children: [
          {
            path: "containers",
            name: "Containers",
            component: Containers,
          },
          {
            path: "manifests",
            name: "ContainerManifests",
            component: ContainerManifests,
          },
        ],
      },
      {
        path: "/shipmentdetails",
        name: "Shipment details",
        component: ShipmentDetails,
      },
      {
        path: "/shipmentdetailspage/:shipmentIds?",
        name: "ShipmentDetailsPage",
        component: ShipmentDetailsPage,
        props: (route) => {
          const shipmentIds = route.params.shipmentIds
            ? route.params.shipmentIds
                .split(",")
                .map((id) => parseInt(id.trim()))
                .filter((id) => !isNaN(id))
                .join(",")
            : "";

          return {
            shipmentIds,
          };
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const requiresLogin = to.matched.some((route) => route.meta.loggedIn);

  if (requiresLogin && !store.state.loggedIn) {
    // Gebruiker is nog niet ingelogd:
    next({
      path: "/login",
      query: {
        next: to.path,
      },
    });
    return;
  }

  next();
});

export default router;
