<template>
  <v-dialog v-model="showDialog" width="660px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Show overdue shipments
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12"> content </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { FrequentActionsApi } from "@/openapi";
interface IProps {
  show: boolean;
}
const api = new FrequentActionsApi(undefined, "");
const props = defineProps<IProps>();
const emit = defineEmits(["close"]);

const ids = ref("");
const hasIds = computed(() => ids.value.length > 0);
const showDialog = computed({
  get() {
    return props.show;
  },
  set() {
    emit("close");
  },
});

const closeDialog = () => {
  emit("close");
};
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
.button-holder {
  text-align: bottom;
}
</style>
